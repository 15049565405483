import React, { useState, useEffect } from "react";
import RobotaxiLogin from "../../Images/LoginImage.png";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AccountInformation = (props) => {
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
  const navigate = useNavigate();
  const EntityRegistration = props.loginUserDetails;
  const [errors, setErrors] = useState({});
  const [postId, setPostId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openDuplicate, setOpenDuplicate] = React.useState(false);
  const [rangeValue, setRangeValue] = useState(100);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [entityId, setEntityId] = useState(null);

  const [formData, setFormData] = useState({
    EmailId: "",
    Password: "",
    ConfirmPassword: "",
    TandC: false,
    TandC2: false,
  });
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
    noSpaces:false
  });

  const backgroundStyle = {
    background: `linear-gradient(90deg, green ${rangeValue}%, rgb(230 224 224) )`,
  };

  useEffect(() => {
    const EntityRegistration = props.loginUserDetails;
    setFormData((prevState) => ({
      ...prevState,
      ...EntityRegistration,
    }));
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!formData.TandC) {
      errors.TandC = "Agree Terms of Service";
    }
    if (!formData.TandC2) {
      errors.TandC2 = "Agree Terms of Use";
    }

    if (!formData.EmailId) {
      errors.EmailId = "Email is required";
    }

    if (!formData.Password) {
      errors.Password = "Password is required";
    } else if (!validatePassword(formData.Password)) {
      errors.Password = "Passwords requirements are not satisified: ";
    }
    if (!formData.ConfirmPassword) {
      errors.ConfirmPassword = "Confirm Password is required";
    } else if (formData.Password != formData.ConfirmPassword) {
      errors.ConfirmPassword = "Please ensure the Passwords match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const registrationButtonClick = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const updatedFormData = { ...EntityRegistration, ...formData };
      const requestOptions = {
        method: "POST",
        headers: { "x-functions-key": `${hostHeader}` },
        body: JSON.stringify(updatedFormData),
      };
      axios
        .post(`${hostPath}/api/entityregistration`, requestOptions.body, {
          headers: requestOptions.headers,
        })
        .then((response) => {
          const errors = {};
          if (response.data.status === true) {
            setPostId(response.data.id);
            localStorage.clear();
            const LogedInUserRes = response.data.responseMessage;
            const entityID = response?.data?.userData?.entityRegistrationID;
            setEntityId(entityID);
            localStorage.setItem(
              "LogedInUser",
              JSON.stringify(entityID)
            );
            if (entityID) {
              fetchUserDetails(entityID);
            }
          } else {
            if (response.data.status === false) {
              errors.EmailId = response.data.responseMessage;
            }
          }
          setErrors(errors);
          return Object.keys(errors).length === 0;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const fetchUserDetails = async (entityID) => {
    try {
      const response = await fetch(`${hostPath}/api/GetUserDetailsbyid`, {
        method: 'POST',
        headers: { "x-functions-key": `${hostHeader}` },
        body: JSON.stringify({ EntityRegistrationID: entityID }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const firstInitial = data[0]?.firstName && data[0]?.firstName?.length > 1 ? data[0]?.firstName?.charAt(0) : '';
      const lastInitial = data[0]?.lastName && data[0]?.lastName?.length > 1 ? data[0]?.lastName?.charAt(0) : '';
      const initials = firstInitial && lastInitial ? (firstInitial + lastInitial).toUpperCase() : '';
      localStorage.setItem('initials', JSON.stringify(initials));
      navigate("/dashboard");
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleCheckboxChange = () => {
    setFormData({
      ...formData,
      TandC: !formData.TandC,
    });
    errors.TandC = "";
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDuplicate = () => {
    setOpenDuplicate(true);
  };

  const handleCloseDuplicate = () => {
    setOpenDuplicate(false);
  };

  const handleCheckboxChange2 = () => {
    setFormData({
      ...formData,
      TandC2: !formData.TandC2,
    });
    errors.TandC2 = "";
  };



  const handleClose = () => {
    setOpen(false);
  };

  const validatePassword = (password) => {
    const requirements = {
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
      noSpaces: !/\s/.test(password),
    };
    setPasswordRequirements(requirements);
    const allRequirementsMet = Object.values(requirements).every(requirement => requirement);
    return allRequirementsMet;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    clearError(name); // Clear error for the corresponding input field
    if (name === "Password") {
      validatePassword(value);
    }
  };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => {
      return { ...prevErrors, [fieldName]: "" };
    });
  };

  const goBack = () => {
    if(props && props.onEnityInfo && props.onEnityInfo()){
    props?.onEnityInfo();
    }
  };

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-md-7 p-0">
          <img src={RobotaxiLogin} alt="Login" className="GeoparkingImage" />
        </div>
        <div className="col-md-5">
          <div className="contain">
            <div className="registersteps formbox">Step 3 of 3</div>
            <input
              type="range"
              className="range-input"
              value={rangeValue}
              min="0"
              max="100"
              style={{
                ...backgroundStyle,
                width: "80%",
                height: "5px",
                padding: "0",
                borderRadius: "5.5px",
              }}
            />
            <form className="login-form ">
              <div className=" login">
                <h3 className="loginheader textleft">Account Information</h3>
                <p className="mb-4 loginDescription textleft">
                  Choose Email and Password to complete your registration.
                </p>
              </div>
              <div className="Username mb-3 textleft">
                <label htmlFor="EmailId" className="userName loginlabel ">
                  Email
                </label>
                <span className="mandatorymark loginlabel">*</span>
                <input
                  type="text"
                  className={`form-control ${formData.EmailId ? "input-active" : ""
                    }`}
                  id="EmailId"
                  placeholder="Enter Email"
                  name="EmailId"
                  value={formData?.EmailId?.toLowerCase()}
                  onChange={handleChange}
                  readOnly
                />
                {errors.EmailId && (
                  <div className="error-message mandatorymark">
                    {errors.EmailId}
                  </div>
                )}
              </div>
              <div className="Password mb-3 textleft">
                <style>
                  {`
                  input::-ms-reveal {
                  display: none;
                  }
                `}
                </style>
                <label htmlFor="password" className="userName loginlabel">
                  Password
                </label>
                <span className="mandatorymark loginlabel">*</span>
                <div
                  className="password-container"
                  style={{ position: "relative" }}
                >
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className={`form-control ${formData.Password ? "input-active" : ""
                      }`}
                    id="password"
                    placeholder="Enter Password"
                    name="Password"
                    value={formData.Password}
                    onChange={handleChange}
                  />
                  <IconButton
                    data-testid="toggle-password-visibility"
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
                {errors.Password && (
                  <div className="error-message mandatorymark">
                    {errors.Password}
                  </div>
                )}
                <ul style={{ listStyleType: "none", paddingLeft: 0, paddingTop: 10 }}>
                  {" "}
                  {/* Remove padding to align to the extreme left */}
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.minLength ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.minLength ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    At least 8 characters
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.uppercase ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.uppercase ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one uppercase letter (A-Z)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.lowercase ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.lowercase ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one lowercase letter (a-z)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.digit ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.digit ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one digit (0-9)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.specialChar ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.specialChar ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one special character (!, @, #, $, %, ^,
                    &, *)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.noSpaces && formData?.Password?.length > 0  ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.noSpaces && formData?.Password?.length > 0 ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                   Does not contain spaces.
                  </li>
                </ul>
              </div>
              <div className="Password mb-3 textleft">
                <style>
                  {`
                  input::-ms-reveal {
                  display: none;
                  }
                `}
                </style>
                <label
                  htmlFor="confirmPassword"
                  className="userName loginlabel"
                >
                  Confirm Password
                </label>
                <span className="mandatorymark loginlabel">*</span>
                <div
                  className="password-container"
                  style={{ position: "relative" }}
                >
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    className={`form-control ${formData.ConfirmPassword ? "input-active" : ""
                      }`}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    name="ConfirmPassword"
                    value={formData.ConfirmPassword}
                    onChange={handleChange}
                  />
                  <IconButton
                    data-testid="toggle-confirm-password-visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {confirmPasswordVisible ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </div>
                {errors.ConfirmPassword && (
                  <div className="error-message mandatorymark">
                    {errors.ConfirmPassword}
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div className="remember">
                  <label className="control control--checkbox mb-0">
                    <input
                      type="checkbox"
                      checked={formData.TandC2}
                      onChange={handleCheckboxChange2}
                    />{" "}
                    <span className="">
                      I Agree to{" "}
                      <a className="joingeo" onClick={handleClickOpenDuplicate}>
                        {" "}
                        Terms of Use
                      </a>{" "}
                    </span>
                    <div className="control__indicator"></div>
                  </label>
                  {errors.TandC2 && (
                    <div className="error-message mandatorymark">
                      {errors.TandC2}
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between mb-5">
                <div className="remember">
                  <label className="control control--checkbox mb-0">
                    <input
                      type="checkbox"
                      checked={formData.TandC}
                      onChange={handleCheckboxChange}
                    />{" "}
                    <span className="">
                      I Agree to{" "}
                      <a className="joingeo" onClick={handleClickOpen}>
                        {" "}
                        Terms of Service
                      </a>{" "}
                    </span>
                    <div className="control__indicator"></div>
                  </label>
                  {errors.TandC && (
                    <div className="error-message mandatorymark">
                      {errors.TandC}
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <Link onClick={goBack} className="joingeo">
                  Go Back
                </Link>
                <button
                  type="submit"
                  className="nextBtn"
                  onClick={registrationButtonClick}
                >
                  Register
                </button>
              </div>
              <div className="joinus remember" style={{ textAlign: "end" }}>
                Already have an account?
                <a href="/login" className="joingeo">Log in</a>here.
              </div>
            </form>
          </div>
        </div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            dividers
            sx={{ textAlign: "center" }}
            id="customized-dialog-title"
          >
            Parking Lot Managment Terms of Service
          </DialogTitle>

          <DialogContent dividers>
            <Box
              sx={{
                textAlign: "start",
                maxHeight: "50vh",
                overflowY: "auto",
                padding: "0.5rem 5rem",
              }}
            >
              <Typography gutterBottom>
                <span className="subcontent">
                  {" "}
                  Date of Last Revision: August 1, 2024:
                </span>
              </Typography>
              <Typography gutterBottom>
                <h1>Terms of Service</h1>

              </Typography>

              <Typography gutterBottom>
                <h2>Introduction</h2>
                <p>
                  GeoParking Technologies Inc. (“GeoParking” “GPT” “we” “us” “our”)
                  provides its services (described below) to you through its website
                  located at{" "}
                  <a href="https://www.geoparking.com"> https://www.geoparking.com</a>{" "}
                  (the “Site”) and through its mobile applications (if any) and through its mobile applications (if any) and related services (collectively, such services, including any new features and applications, and the Site, the “Services”), subject to the following Parking Lot Management Terms of Service (as amended from time to time, the “Terms” or “Terms of Service”). These Terms are solely intended to be used by owners or managers of parking lots or parking spaces who wish to use the Services to enable autonomous vehicles to use parking spots and lots within their control to pick up and drop off riders or to park between riders. For purposes of these Terms of Service, we use the term “Robotaxi” to refer to any autonomous vehicle, whether operated by a robotaxi business entity or owned by a private individual, that is designed primarily to pick up, carry, and drop off passengers, and park completely autonomously.
                </p>
                <p>
                  Note that these Parking Lot Management Terms of Service are separate
                  and distinct from GeoParking’s Website Terms of Use which govern the
                  use of our website and related apps at <a target="_blank" href="/terms-of-use"> Terms of Use</a>.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>Key Provisions</h2>
                <p>
                  <strong>Easy Termination By You:</strong> These Terms of Service can
                  be terminated with 72 hours’ written (email to suffice) notice by you
                  for any reason or no reason as stated in the <a href="#termination">Term and Termination</a> section below.
                </p>
                <p>
                  <strong>Arbitration:</strong> PLEASE READ THESE TERMS OF SERVICE
                  CAREFULLY AS THEY CONTAIN AN AGREEMENT TO <a href="#arbitration"> ARBITRATE</a> AND OTHER
                  IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS REMEDIES AND
                  OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED
                  EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND
                  FINAL ARBITRATION AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE
                  CLAIMS AGAINST GEOPARKING ON AN INDIVIDUAL BASIS NOT AS A PLAINTIFF OR
                  CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING (2)
                  YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY
                  INJUNCTIVE AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS AND (3) YOU
                  MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A
                  JURY OR IN A COURT OF LAW. YOU CAN OPT OUT OF THIS AGREEMENT TO
                  ARBITRATE BY CONTACTING <a target="_blank" href="mailto:disputes@geoparking.com">disputes@geoparking.com</a>
                  WITHIN 30 DAYS AFTER FIRST ACCEPTING THESE TERMS OF SERVICE AND
                  STATING YOUR FIRST AND LAST NAME AND THAT YOU DECLINE THE TERMS TO
                  ARBITRATE.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>Access and Use of the Service</h2>
                <h3>Services Description</h3>
                <p>
                  The Service offers a platform for entities or individuals who own, manage, or hold rights to use parking spots in a particular location (e.g. commercial retail commercial
                  industrial commercial office government educational residential home
                  residential building/community religious establishment etc.) (collectively, Parking Spot Owners”). This
                  platform allows Parking Spot Owners to (i) connect with owners and
                  operators of Robotaxis or privately-owned autonomous vehicles that
                  need a parking spot to use between fares; (ii) define limits on
                  duration of use by such vehicles; and (iii) indicate specific spots
                  (or general locations) for use by these vehicles during
                  optionally-specified times all as further described by the Service’s
                  Documentation (defined as the totality of the website help articles
                  and technical documentation made available by us at{" "}
                  <a href="https://www.GeoParking.com">https://www.GeoParking.com</a>).
                  Anyone who accesses or uses the Service whether as a Parking Spot
                  Owner Robotaxi operator or owner of a private autonomous vehicle or
                  acting on behalf of themselves or a third party will be referred to
                  herein as a “user.”
                </p>
                <h3>Your Registration Obligations</h3>
                <p>
                  You are required to register with GeoParking in order to access and
                  use the Service. If you choose to use the Service you agree to provide
                  and maintain true accurate current and complete information about
                  yourself or the entity you represent as prompted by the Service’s
                  registration form. Registration data and certain other information
                  about you are governed by our <a target="_blank" href="/privacy-policy">Privacy Policy</a>. If you are under 18
                  years of age you are not authorized to use the Service with or without
                  registering. By accessing or using the Service you represent and
                  warrant that you are at least 18 years of age and that you have not
                  been previously suspended or removed from the Service.
                </p>
                <h3>Member Account Password and Security</h3>
                <p>
                  To use the Services, you must register for a “Parking Lot Management” account and will need to provide us with information about yourself, such as your name, email address, mobile number, and details about your parking spot(s). You agree that the information you provide to us is accurate and that you will keep it accurate and up to date at all times. Your account is personal to you (or the entity you represent), and your account and any related benefits, including discount codes or similar benefits may not be shared with third parties, including other users (unless such users are part of an entity’s account). You are responsible for maintaining the confidentiality of your password and account and are fully responsible for any and all activities that occur under your password or account. You agree to (a) immediately notify GeoParking of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session when accessing the Service (or otherwise take reasonable steps to avoid the misuse of your account). GeoParking will not be liable for any loss or damage arising from your failure to comply with this Section. You further agree to indemnify, defend, and hold harmless the GeoParking Parties (as defined in Warranties and Disclaimers) from and against any claims, losses, damages, and other harm arising in connection with the unauthorized use (including without limitation any use by a person or entity other than you, and any use not expressly permitted by these Terms of Service) of your account credentials.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>Modifications to Service</h2>
                <p>
                  GeoParking reserves the right to modify or discontinue temporarily or
                  permanently the Service (or any part thereof) with or without notice.
                  You agree that GeoParking will not be liable to you or to any third
                  party for any modification suspension or discontinuance of the
                  Service.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>General Practices Regarding Use and Data Retention</h2>
                <p>
                  You acknowledge that GeoParking may establish general practices and limits concerning use of the Service, including without limitation the maximum period of time that data or other content will be retained by the Service and the maximum storage space that will be allotted on GeoParking’s servers (or on GeoParking’s account with a cloud service provider) on your behalf. You agree that GeoParking has no responsibility or liability for the deletion or failure to store any data or other content maintained or uploaded by the Service. You acknowledge that GeoParking reserves the right to terminate accounts that are inactive for an extended period of time. You further acknowledge that GeoParking reserves the right to change these general practices and limits at any time, in its sole discretion, with or without notice.</p>

              </Typography>

              <Typography gutterBottom>
                <h2>Conditions of Use</h2>
                <h3>Parking Lot Management Listings and Review</h3>
                <p>
                  When creating a Robotaxi parking lot or spot listing (a “Listing”) one
                  or more of the following details may be added at the sole discretion
                  of the Parking Spot Owner as explained in the Documentation:
                </p>
                <ul>
                  <li>Designated entrance(s) and exit(s) to the parking lot;</li>
                  <li>
                    Designated pick up and drop off locations within or around the
                    parking lot;
                  </li>
                  <li>Designated parking spots within the parking lot and;</li>
                  <li>
                    Day and time-based availability for parking designations, as desired.
                  </li>
                </ul>
                <p>
                  Parking Spot Owners are responsible for providing complete and
                  accurate information in order to make the above designations using the
                  tools made available in the Services and described in the
                  Documentation.
                </p>
                <p>
                  Parking Spot Owners are solely responsible for keeping Listings
                  (particularly day and time availability) up to date. By creating a
                  Listing you hereby grant permission for GeoParking to conduct certain
                  technical reviews of the Listing including but not limited to
                  measurements of the parking spot and the parking lot in which the
                  parking spot exists via satellite imagery publicly provided
                  photographs and maps or live video LIDAR or radar footage of the
                  Listing for the purpose of ensuring that Robotaxis can safely navigate
                  to and use the Listing.
                </p>
                <p>
                  Parking Spot Owners represent and warrant that any Listing they post
                  and the sharing of such parking spot(s) will (i) not breach any
                  agreements entered into with any third parties such as homeowners
                  association condominium employment lease or other agreements and (ii)
                  comply with all applicable laws (such as zoning laws) and other rules
                  and regulations (including having all required permits licenses and
                  registrations) and will in no event result in GeoParking violating any
                  applicable law or being miscategorized as a parking operator of
                  record.
                </p>
                <p>
                  The utilization of the Listing by Robotaxis will vary and depends on a variety of factors, such as Robotaxis’ search parameters, needs and preferences, price, Listing size and availability of electric vehicle (EV) charging infrastructure, and availability, etc. As such, GeoParking makes no representations regarding the number of times a Listing will be used, which directly impacts the income derived from such Listing (see Fees below).
                </p>
                <p>
                  By creating a Listing and using the Services you hereby grant all
                  reasonably necessary licenses to all Robotaxis using the GeoParking
                  Services to enter any premises needed to access the Listing. You also
                  grant all reasonably necessary licenses to any persons or additional
                  vehicles reasonably needed to fix malfunctioning Robotaxis which may
                  include service personnel entering the premises and if necessary the
                  use of tow trucks. You also acknowledge and agree that nothing in
                  these Parking Lot Management Terms of Service substitutes for or
                  eliminates the need for regular parking enforcement in your parking
                  lots.
                </p>
                <h3>Fees</h3>
                <p>
                  When Robotaxis park at your Listings they are charged <a target='_blank' href='/settings/privacy'>parking fees</a>.
                  The amount of the  <a target='_blank' href='/settings/privacy'>parking fee</a> is determined solely by GeoParking using
                  multiple proprietary methods and is displayed in your GeoParking
                  Parking Spot Owner account. If you at any time do not want to accept
                  Robotaxis for the listed  <a target='_blank' href='/settings/privacy'>parking fee</a> you may use the Services to
                  temporarily disable your Listings as described by the Documentation.
                  The  <a target='_blank' href='/settings/privacy'>parking fees</a> are collected from the Robotaxi operator by
                  GeoParking and distributed to you on a monthly basis using the
                  financial account(s) designated by you in the Services. The percentage
                  of the total  <a target='_blank' href='/settings/privacy'>parking fee</a> you will receive is published in your account
                  and may change from time to time. <a target='_blank' href='/settings/privacy'>Fees</a> will accumulate in your account
                  and will be paid to you at the end of each month unless the total
                  accumulated fee is less than $25 USD in which case GeoParking will
                  rollover that amount into the next month. You understand and agree
                  that providing GeoParking with incorrect financial account information
                  may result in a delay in receiving  <a target='_blank' href='/settings/privacy'>parking fees</a>.
                </p>
                <h3>Exclusivity</h3>
                <p>
                  By using the Services you agree not to use any other similar service
                  that purports to pay fees to Parking Spot Owners (or similar term) in
                  exchange for allowing Robotaxis (or any similar term) to use their
                  parking lots (or designated parking spots within their parking lots)
                  as a location to park Robotaxis (a “Competing Service”). If you begin
                  using a Competing Service you must immediately notify us of that fact
                  which will also serve to immediately terminate these Parking Lot
                  Management Terms of Service. For purposes of this section any
                  parking-related service that helps human drivers find parking spots is
                  not considered a Competing Service. Additionally, you agree not to enter into any agreements directly with robotaxi companies (including ride-share apps offering robotaxi services), car manufacturers or their wholly-owned robotaxi subsidiaries, or any other company offering to pay parking fees for robotaxis operated by itself or a third party.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>Intellectual Property Rights</h2>
                <h3>Service Content Software and Trademarks</h3>
                <p>
                  You acknowledge and agree that the Service may contain content or
                  features (“Service Content”) that are protected by copyright patent
                  trademark trade secret or other proprietary rights and laws. Except as
                  expressly authorized by GeoParking you agree not to modify copy frame
                  scrape rent lease loan sell distribute or create derivative works
                  based on the Service or the Service Content in whole or in part except
                  that the foregoing does not apply to your own User Content (as defined in User Content Transmitted Through the Service) that you legally upload to the Service. In connection with your
                  use of the Service you will not engage in or use any data mining
                  robots scraping or similar data gathering or extraction methods. If
                  you are blocked by GeoParking from accessing the Service (including by
                  blocking your IP address) you agree not to implement any measures to
                  circumvent such blocking (e.g. by masking your IP address or using a
                  proxy IP address). Any use of the Service or the Service Content other
                  than as specifically authorized herein is strictly prohibited. The
                  technology and software underlying the Service or distributed in
                  connection therewith are the property of GeoParking our affiliates and
                  our partners (the “Software”). You agree not to copy modify create a
                  derivative work of reverse engineer reverse assemble or otherwise
                  attempt to discover any source code sell assign sublicense or
                  otherwise transfer any right in the Software. Any rights not expressly
                  granted herein are reserved by GeoParking.
                </p>
                <p>
                  The GeoParking name and logos are trademarks and service marks of
                  GeoParking (collectively the “GeoParking Trademarks”). Other company
                  product and service names and logos used and displayed via the Service
                  may be trademarks or service marks of their respective owners who may
                  or may not endorse or be affiliated with or connected to GeoParking.
                  Nothing in these Terms of Service or the Service should be construed
                  as granting by implication estoppel or otherwise any license or right
                  to use any of GeoParking Trademarks displayed on the Service without
                  our prior written permission in each instance. All goodwill generated
                  from the use of GeoParking Trademarks will inure to our exclusive
                  benefit.
                </p>
                <h3>Third Party Material/Services</h3>
                <p>
                  Under no circumstances will GeoParking be liable in any way for any
                  content or materials of any third parties (including users) including
                  but not limited to for any errors or omissions in any content or for
                  any loss or damage of any kind incurred as a result of the use of any
                  such content. You acknowledge that GeoParking does not pre-screen
                  content but that GeoParking and its designees will have the right (but
                  not the obligation) in their sole discretion to refuse or remove any
                  content that is available via the Service. Without limiting the
                  foregoing GeoParking and its designees will have the right to remove
                  any content that violates these Terms of Service or is deemed by
                  GeoParking in its sole discretion to be otherwise objectionable. You
                  agree that you must evaluate and bear all risks associated with the
                  use of any content including any reliance on the accuracy completeness
                  or usefulness of such content.
                </p>
                <p>
                  The Services may provide you with access to third-party websites
                  databases networks servers information software programs systems
                  directories applications or products or services (collectively
                  “Third-Party Services”). GeoParking does not have or maintain any
                  control over Third-Party Services and is not and cannot be responsible
                  for their content operation or use. By linking or otherwise displaying
                  information from or providing access to any Third-Party Services
                  GeoParking does not give any representation warranty or endorsement
                  express or implied with respect to the legality accuracy quality or
                  authenticity of content information or services provided by those
                  Third-Party Services.
                </p>
                <p>
                  Third-Party Services may have their own terms of use and privacy
                  notices and may have different practices and requirements to those
                  operated by GeoParking with respect to the Service. You are solely
                  responsible for reviewing any terms of use privacy notice or other
                  terms governing your use of these Third-Party Services which you use
                  at your own risk. You are advised to make reasonable inquiries and
                  investigations before entering into any transaction financial or
                  otherwise and whether online or offline with any third party related
                  to any Third-Party Services.
                </p>
                <p>
                  You are solely responsible for taking the precautions necessary to
                  protect yourself from fraud when using Third-Party Services and to
                  protect your computer systems from viruses worms Trojan horses and
                  other harmful or destructive content and material that may be included
                  on or may emanate from any Third-Party Services. GeoParking disclaims
                  any and all responsibility or liability for any harm resulting from
                  your use of Third-Party Services and you hereby irrevocably waive any
                  claim against GeoParking with respect to the content or operation of
                  any Third-Party Services.
                </p>
                <p>
                  The software you download consists of a package of components
                  including certain third-party software (“Third-Party Software”)
                  provided under separate license terms (the “Third Party Terms”). Your
                  use of the Third-Party Software in conjunction with the Services in a
                  manner consistent with the terms of these Terms is permitted however
                  you may have broader rights under the applicable Third-Party Terms and
                  nothing in these Terms is intended to impose further restrictions on
                  your use of the Third-Party Software.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>User Content Transmitted Through the Service</h2>
                <p>
                  With respect to the content or other materials you upload through the
                  Service or share with other users or recipients (collectively “User
                  Content”) including Listings and details of the Listings you represent
                  and warrant that you own all right, title and interest in and to such
                  User Content including without limitation all copyrights and rights of
                  publicity contained therein. By uploading any User Content you hereby
                  grant and will grant GeoParking and its affiliated companies a
                  nonexclusive worldwide royalty free fully paid up transferable
                  sublicensable perpetual irrevocable license to copy display upload
                  perform distribute store modify and otherwise use your User Content in
                  connection with the operation of the Service or the promotion
                  advertising or marketing thereof in any form medium or technology now
                  known or later developed.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>Feedback</h2>
                <p>
                  You acknowledge and agree that any questions comments suggestions
                  ideas feedback or other information about the Service (“Submissions”)
                  provided by you to GeoParking are non-confidential and GeoParking will
                  be entitled to the unrestricted use and dissemination of these
                  Submissions for any purpose commercial or otherwise without
                  acknowledgment or compensation to you.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>Data Retention</h2>
                <p>
                  You acknowledge and agree that GeoParking may preserve content and may
                  also disclose content if required to do so by law or in the good faith
                  belief that such preservation or disclosure is reasonably necessary
                  to: (a) comply with legal process applicable laws or government
                  requests; (b) enforce these Terms of Service; (c) respond to claims
                  that any content violates the rights of third parties; or (d) protect
                  the rights property or personal safety of GeoParking its users and the
                  public. You understand that the technical processing and transmission
                  of the Service including your content may involve (a) transmissions
                  over various networks; and (b) changes to conform and adapt to
                  technical requirements of connecting networks or devices.
                </p>
              </Typography>
              <Typography gutterBottom>
                <h2>Publicity and Logo Usage</h2>
                <p>
                  You agree that GeoParking may display your name and logo, if any, on its website as an active user and participating parking lot and hereby grant GeoParking a reasonable license to that effect. However, we will not publicly share your contact information or the precise address of your parking lots on our website.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>Third Party Websites</h2>
                <p>
                  The Service may provide or third parties may provide links or other
                  access to other sites and resources on the Internet. GeoParking has no
                  control over such sites and resources and GeoParking is not
                  responsible for and does not endorse such sites and resources. You
                  further acknowledge and agree that GeoParking will not be responsible
                  or liable directly or indirectly for any damage or loss caused or
                  alleged to be caused by or in connection with use of or reliance on
                  any content events goods or services available on or through any such
                  site or resource. Any dealings you have with third parties found while
                  using the Service are between you and the third party and you agree
                  that GeoParking is not liable for any loss or claim that you may have
                  against any such third party.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>Warranties and Disclaimers</h2>
                <p>
                  To the fullest extent permitted by law the Services are provided “as
                  is” with all faults and the entire risk as to satisfactory quality
                  performance accuracy safety and effort is with you. You acknowledge
                  that because of the nature of the internet mobile networks and the
                  devices which access the internet and/or mobile networks the Services
                  may not be accessible when needed and that information data audio and
                  video transmitted over the internet and/or mobile networks may be
                  subject to interruption or third-party interception and modification.
                  To the fullest extent permitted by law GeoParking disclaims any and
                  all warranties or conditions express or implied or statutory including
                  but not limited to the implied warranties or conditions of
                  merchantability fitness for a particular purpose quiet enjoyment
                  safety non-infringement accuracy of informational content absence of
                  viruses and damaging or disabling code tax-exempt status and any
                  warranties arising out of course of dealing or usage or trade.
                </p>
                <p>
                  To the fullest extent permitted by law, none of GeoParking, GeoParking’s affiliates, employees, agents, contractors, representatives, content providers, or service providers, and none of the Parking Spot Owners of the parking lots or spots, or their affiliates, employees, agents, contractors, representatives (collectively, the “GeoParking Parties”) warrant the accuracy, completeness, or timeliness of the Services. The GeoParking Parties cannot and do not assume any responsibility for any loss, damages, or liabilities arising from the failure of any telecommunications infrastructure, or the internet or for your misuse of any of content and information accessed through the Services. You expressly agree that your use of the Services and your reliance upon any of the respective content is at your sole risk.
                </p>
                <p>
                  You acknowledge that GeoParking is not responsible for the Listings
                  and parking spots provided by Parking Spot Owners and that it has no
                  right to and does not control direct or supervise Parking Spot Owners
                  Robotaxis or third parties who may be in attendance in the parking lot
                  or spot.
                </p>
                <p>
                  GeoParking does not create or deliver parking spots or Listings. To
                  the maximum extent permitted by law the GeoParking Parties will not be
                  liable for any damage arising out of or in connection with parking
                  spots Listings or a Robotaxi’s use of any parking spot whether based
                  on warranty contract tort (including negligence) product liability or
                  any other legal theory. Whether you are a Parking Spot Owner or
                  Robotaxi operator you alone are responsible for identifying
                  understanding and complying with all laws that apply to your use of
                  the Services.
                </p>
                <p>
                  GeoParking makes no warranty that (i) the Services will meet your
                  requirements (ii) the Services will be uninterrupted timely secure or
                  error-free (iii) the results that may be obtained from the use of the
                  service will be accurate or reliable or (iv) the quality of any
                  products Services information or other material purchased or obtained
                  by you through the service will meet your expectations.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>Indemnity and Release</h2>
                <p>
                  You agree to release defend indemnify and hold the GeoParking Parties
                  harmless from any and all losses damages expenses including reasonable
                  attorneys’ fees rights claims actions of any kind and injury
                  (including death) arising out of or relating to your access to or use
                  of the Service any User Content your connection to the Service your
                  violation of these Terms of Service or your violation of any rights of
                  another.
                </p>
                <p>
                  To the fullest extent permitted by law you further waive release and
                  forever discharge the GeoParking Parties from any and all
                  responsibility or liability for injuries or damages resulting from any
                  service obtained through the use of the Services including injuries or
                  damages caused by the negligent act or omission of the GeoParking
                  Parties or in any way arising out of or connected with the Services.
                </p>
                <p>
                  We reserve the right at our own expense to assume the exclusive
                  defense and control of any matter otherwise subject to indemnification
                  by you and in such case you agree to cooperate with our defense of
                  such claim. You will not in any event settle any claim or matter
                  without our written consent.
                </p>
                <p>
                  If you are a California resident you waive California Civil Code
                  Section 1542 which says: “A general release does not extend to claims
                  which the creditor does not know or suspect to exist in his favor at
                  the time of executing the release which if known by him must have
                  materially affected his settlement with the debtor.” If you are a
                  resident of another jurisdiction you waive any comparable statute or
                  doctrine.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>Limitation of Liability</h2>
                <p>
                  YOU EXPRESSLY UNDERSTAND AND AGREE THAT GEOPARKING WILL NOT BE LIABLE
                  FOR ANY INDIRECT INCIDENTAL SPECIAL CONSEQUENTIAL EXEMPLARY DAMAGES OR
                  DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO DAMAGES FOR
                  LOSS OF GOODWILL USE DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
                  GEOPARKING HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES)
                  WHETHER BASED ON CONTRACT TORT NEGLIGENCE STRICT LIABILITY OR
                  OTHERWISE RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE
                  SERVICE; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
                  RESULTING FROM ANY GOODS DATA INFORMATION OR SERVICES PURCHASED OR
                  OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR
                  FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                  TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY
                  ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE SERVICE. IN NO
                  EVENT WILL GEOPARKING’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES LOSSES
                  OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID GEOPARKING IN THE
                  LAST SIX (6) MONTHS OR IF GREATER ONE HUNDRED DOLLARS ($100).
                </p>
                <p>
                  SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN
                  WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL
                  OR CONSEQUENTIAL DAMAGES. ACCORDINGLY SOME OF THE ABOVE LIMITATIONS
                  SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO
                  YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH
                  THESE TERMS OF SERVICE YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                  DISCONTINUE USE OF THE SERVICE.
                </p>
                <p>
                  IF YOU ARE A USER FROM NEW JERSEY THE SECTIONS TITLED “DISCLAIMER OF
                  WARRANTIES” AND “LIMITATION OF LIABILITY” ARE INTENDED TO BE ONLY AS
                  BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF
                  ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS OF
                  THE STATE OF NEW JERSEY THE INVALIDITY OF SUCH PORTION SHALL NOT
                  AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE
                  SECTIONS.
                </p>
              </Typography>

              <Typography gutterBottom>
                <section id="arbitration">
                  <h2>Dispute Resolution By Binding Arbitration</h2>
                  <p>PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.</p>
                  <h3>a. Agreement to Arbitrate</h3>
                  <p>
                    This Dispute Resolution by Binding Arbitration section is referred to
                    in these Parking Lot Management Terms of Service as the “Arbitration
                    Agreement.” You agree that any and all disputes or claims that have
                    arisen or may arise between you and GeoParking whether arising out of
                    or relating to this Parking Lot Management Terms of Service (including
                    any alleged breach thereof) the Services any advertising any aspect of
                    the relationship or transactions between us shall be resolved
                    exclusively through final and binding arbitration rather than a court
                    in accordance with the terms of this Arbitration Agreement except that
                    you may assert individual claims in small claims court if your claims
                    qualify. Further this Arbitration Agreement does not preclude you from
                    bringing issues to the attention of federal state or local agencies
                    and such agencies can if the law allows seek relief against us on your
                    behalf. You agree that by entering into these Parking Lot Management
                    Terms of Service you and GeoParking are each waiving the right to a
                    trial by jury or to participate in a class action. Your rights will be
                    determined by a neutral arbitrator not a judge or jury. The Federal
                    Arbitration Act governs the interpretation and enforcement of this
                    Arbitration Agreement.
                  </p>
                  <h3>
                    b. Prohibition of Class and Representative Actions and
                    Non-Individualized Relief
                  </h3>
                  <p>
                    YOU AND GEOPARKING AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE
                    OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS
                    MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
                    UNLESS BOTH YOU AND GEOPARKING AGREE OTHERWISE THE ARBITRATOR MAY NOT
                    CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY
                    NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED REPRESENTATIVE
                    OR CLASS PROCEEDING. ALSO THE ARBITRATOR MAY AWARD RELIEF (INCLUDING
                    MONETARY INJUNCTIVE AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE
                    INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO
                    PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S) EXCEPT
                    THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD PUBLIC
                    INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT REQUIRED FOR THE
                    ENFORCEABILITY OF THIS PROVISION.
                  </p>
                  <h3>c. Pre-Arbitration Dispute Resolution</h3>
                  <p>
                    GeoParking is always interested in resolving disputes amicably and
                    efficiently and most customer concerns can be resolved quickly and to
                    the customer’s satisfaction by emailing customer support at <a target="_blank" href="mailto:Disputes@GeoParking.com">Disputes@GeoParking.com</a>.
                    If such efforts prove unsuccessful a party who intends to seek
                    arbitration must first send to the other by certified mail a written
                    Notice of Dispute (“Notice”). The Notice to GeoParking should be sent
                    to GeoParking Technologies Inc. 800 Bellevue Way NE Suite #500
                    Bellevue WA 98004 (“Notice Address”). The Notice must (i) describe the
                    nature and basis of the claim or dispute and (ii) set forth the
                    specific relief sought. If GeoParking and you do not resolve the claim
                    within sixty (60) calendar days after the Notice is received you or
                    GeoParking may commence an arbitration proceeding. During the
                    arbitration the amount of any settlement offer made by GeoParking or
                    you shall not be disclosed to the arbitrator until after the
                    arbitrator determines the amount if any to which you or GeoParking is
                    entitled.
                  </p>
                  <h3>d. Arbitration Procedures</h3>
                  <p>
                    Arbitration will be conducted by a neutral arbitrator in accordance
                    with the American Arbitration Association’s (“AAA”) rules and
                    procedures including the AAA’s Consumer Arbitration Rules
                    (collectively the “AAA Rules”) as modified by this Arbitration
                    Agreement. For information on the AAA please visit its website <a target="_blank" href="http://www.adr.org">http://www.adr.org</a>. Information about
                    the AAA Rules and fees for consumer disputes can be found at the AAA’s
                    consumer arbitration page <a target="_blank" href="http://www.adr.org/consumer_arbitration">
                      http://www.adr.org/consumer_arbitration</a>. If there is any
                    inconsistency between any term of the AAA Rules and any term of this
                    Arbitration Agreement the applicable terms of this Arbitration
                    Agreement will control unless the arbitrator determines that the
                    application of the inconsistent Arbitration Agreement terms
                    would not result in a fundamentally fair arbitration. The arbitrator
                    must also follow the provisions of these Parking Lot Management Terms
                    of Service as a court would. All issues are for the arbitrator to
                    decide including but not limited to issues relating to the scope
                    enforceability and arbitrability of this Arbitration Agreement.
                    Although arbitration proceedings are usually simpler and more
                    streamlined than trials and other judicial proceedings the arbitrator
                    can award the same damages and relief on an individual basis that a
                    court can award to an individual under the Parking Lot Management
                    Terms of Service and applicable law. Decisions by the arbitrator are
                    enforceable in court and may be overturned by a court only for very
                    limited reasons.
                  </p>
                  <p>
                    Unless GeoParking and you agree otherwise any arbitration hearings
                    will take place in a reasonably convenient location for both parties
                    with due consideration of their ability to travel and other pertinent
                    circumstances. If the parties are unable to agree on a location the
                    determination shall be made by AAA. If your claim is for $10000 or
                    less GeoParking agrees that you may choose whether the arbitration
                    will be conducted solely on the basis of documents submitted to the
                    arbitrator through a telephonic hearing or by an in-person hearing as
                    established by the AAA Rules. If your claim exceeds $10000 the right
                    to a hearing will be determined by the AAA Rules. Regardless of the
                    manner in which the arbitration is conducted the arbitrator shall
                    issue a reasoned written decision sufficient to explain the essential
                    findings and conclusions on which the award is based.
                  </p>
                  <h3>e. Costs of Arbitration</h3>
                  <p>
                    Payment of all filing administration and arbitrator fees (collectively
                    the “Arbitration Fees”) will be governed by the AAA Rules unless
                    otherwise provided in this Arbitration Agreement. If the value of the
                    relief sought is $25000 or less at your request GeoParking will pay
                    all Arbitration Fees. If the value of relief sought is more than
                    $25000 and you are able to demonstrate to the arbitrator that you are
                    economically unable to pay your portion of the Arbitration Fees or if
                    the arbitrator otherwise determines for any reason that you should not
                    be required to pay your portion of the Arbitration Fees GeoParking
                    will pay your portion of such fees. In addition if you demonstrate to
                    the arbitrator that the costs of arbitration will be prohibitive as
                    compared to the costs of litigation GeoParking will pay as much of the
                    Arbitration Fees as the arbitrator deems necessary to prevent the
                    arbitration from being cost-prohibitive. Any payment of attorneys’
                    fees will be governed by the AAA Rules.
                  </p>
                  <h3>f. Confidentiality</h3>
                  <p>
                    All aspects of the arbitration proceeding and any ruling decision or
                    award by the arbitrator will be strictly confidential for the benefit
                    of all parties.
                  </p>
                  <h3>g. Severability</h3>
                  <p>
                    If a court or the arbitrator decides that any term or provision of
                    this Arbitration Agreement (other than the subsection (b) titled
                    “Prohibition of Class and Representative Actions and
                    Non-Individualized Relief” above) is invalid or unenforceable the
                    parties agree to replace such term or provision with a term or
                    provision that is valid and enforceable and that comes closest to
                    expressing the intention of the invalid or unenforceable term or
                    provision and this Arbitration Agreement shall be enforceable as so
                    modified. If a court or the arbitrator decides that any of the
                    provisions of subsection (b) above titled “Prohibition of Class and
                    Representative Actions and Non-Individualized Relief” are invalid or
                    unenforceable then the entirety of this Arbitration Agreement shall be
                    null and void unless such provisions are deemed to be invalid or
                    unenforceable solely with respect to claims for public injunctive
                    relief. The remainder of the Parking Lot Management Terms of Service
                    will continue to apply.
                  </p>
                  <h3>h. Future Changes to Arbitration Agreement</h3>
                  <p>
                    Notwithstanding any provision in these Terms of Service to the
                    contrary GeoParking agrees that if it makes any future change to this
                    Arbitration Agreement (other than a change to the Notice Address)
                    while you are a user of the Services you may reject any such change by
                    sending GeoParking written notice within thirty (30) calendar days of
                    the change to the Notice Address provided above. By rejecting any
                    future change you are agreeing that you will arbitrate any dispute
                    between us in accordance with the language of this Arbitration
                    Agreement as of the date you first accepted these Parking Lot
                    Management Terms of Service (or accepted any subsequent changes to
                    these Parking Lot Management Terms of Service).
                  </p>
                </section>
              </Typography>

              <Typography gutterBottom>
                <section id="termination">
                  <h2>Termination</h2>
                  <h3>By GeoParking</h3>
                  <p>
                    You agree that GeoParking in its sole discretion may suspend or
                    terminate your account (or any part thereof) or use of the Service and
                    remove and discard any content within the Service for any reason
                    including without limitation for lack of use or if GeoParking believes
                    that you have violated or acted inconsistently with the letter or
                    spirit of these Parking Lot Management Terms of Service. Any suspected
                    fraudulent abusive or illegal activity that may be grounds for
                    termination of your use of Service may be referred to appropriate law
                    enforcement authorities. GeoParking may also in its sole discretion
                    and at any time discontinue providing the Service or any part thereof
                    with or without notice. You agree that any termination of your access
                    to the Service under any provision of this Parking Lot Management
                    Terms of Service may be affected without prior notice and acknowledge
                    and agree that GeoParking may immediately deactivate or delete your
                    account and all related information and files in your account and/or
                    bar any further access to such files or the Service. Further you agree
                    that GeoParking will not be liable to you or any third party for any
                    termination of your access to the Service.
                  </p>
                  <h3>By Users</h3>
                  <p>
                    You may terminate your account and this Agreement at any time for any
                    reason or no reason by providing 72 hours’ written notice to
                    GeoParking. You must send an email to <a target="_blank" href="mailto:Cancellations@GeoParking.com">
                      Cancellations@GeoParking.com</a> with the subject line “CANCEL” using the email address connected to
                    your account. You should include your name and phone number in the
                    body of the email in case we need to contact you regarding your
                    request.
                  </p>
                </section>
              </Typography>

              <Typography gutterBottom>
                <h2>User Disputes</h2>
                <p>
                  You agree that you are solely responsible for your interactions to the
                  extent possible now or in the future with any other user in connection
                  with the Service and GeoParking will have no liability or
                  responsibility with respect thereto. GeoParking reserves the right but
                  has no obligation to become involved in any way with disputes between
                  you and any other user of the Service.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>General</h2>
                <h3>Entire Agreement</h3>
                <p>
                  These Parking Lot Management Terms of Service constitute the entire
                  agreement between you and GeoParking and govern your use of the
                  Service superseding any prior agreements between you and GeoParking
                  with respect to the Service. You also may be subject to additional
                  terms and conditions that may apply when you use affiliate or
                  third-party services third-party content or third-party software.
                </p>
                <h3>Governing Law</h3>
                <p>
                  These Parking Lot Management Terms of Service will be governed by the
                  laws of the State of Washington without regard to its conflict of law
                  provisions. With respect to any disputes or claims not subject to
                  arbitration as set forth above you and GeoParking agree to submit to
                  the personal and exclusive jurisdiction of the state and federal
                  courts located within King County Washington.
                </p>
                <h3>Waiver</h3>
                <p>
                  The failure of GeoParking to exercise or enforce any right or
                  provision of these Parking Lot Management Terms of Service will not
                  constitute a waiver of such right or provision.
                </p>
                <h3>Severability</h3>
                <p>
                  If any provision of these Parking Lot Management Terms of Service is
                  found by a court of competent jurisdiction to be invalid the parties
                  nevertheless agree that the court should endeavor to give effect to
                  the parties’ intentions as reflected in the provision and the other
                  provisions of these Parking Lot Management Terms of Service remain in
                  full force and effect.
                </p>
                <h3>Limitation of Actions</h3>
                <p>
                  You agree that regardless of any statute or law to the contrary any
                  claim or cause of action arising out of or related to use of the
                  Service or these Parking Lot Management Terms of Service must be filed
                  within one (1) year after such claim or cause of action arose or be
                  forever barred.
                </p>
                <h3>Admissibility</h3>
                <p>
                  A printed version of this agreement and of any notice given in
                  electronic form will be admissible in judicial or administrative
                  proceedings based upon or relating to this agreement to the same
                  extent and subject to the same conditions as other business documents
                  and records originally generated and maintained in printed form.
                </p>
                <h3>Assignment</h3>
                <p>
                  You may not assign these Parking Lot Management Terms of Service
                  without the prior written consent of GeoParking but GeoParking may
                  assign or transfer these Parking Lot Management Terms of Service in
                  whole or in part without restriction.
                </p>
                <h3>Section Titles</h3>
                <p>
                  The section titles in these Parking Lot Management Terms of Service
                  are for convenience only and have no legal or contractual effect.
                </p>
                <h3>Notices</h3>
                <p>
                  Notices to you may be made via either email or regular mail. The
                  Service may also provide notice to you of changes to these Parking Lot
                  Management Terms of Service or other matters by displaying notices or
                  links to notices generally on the Service.
                </p>
                <h3>Third-Party Beneficiaries</h3>
                <p>
                  The provisions of these Terms relating to the rights of the GeoParking
                  Parties are intended for the benefit of such GeoParking Parties and
                  such GeoParking Parties as third-party beneficiaries will be entitled
                  to enforce such provisions in accordance with their terms irrespective
                  of the fact that they are not signatories to or otherwise bound by
                  these Terms.
                </p>

              </Typography>

              <Typography gutterBottom>
                <h2>Your Privacy</h2>
                <p>
                  At GeoParking we endeavor to respect the privacy of our users. For
                  details please see our <a target="_blank" href="/privacy-policy">Privacy Policy</a>.
                </p>
              </Typography>

              <Typography gutterBottom>
                <h2>Notice for California Users</h2>
                <p>
                  Under California Civil Code Section 1789.3 users of the Service from
                  California are entitled to the following specific consumer rights
                  notice: The Complaint Assistance Unit of the Division of Consumer
                  Services of the California Department of Consumer Affairs may be
                  contacted in writing at 1625 North Market Blvd. Suite N 112 Sacramento
                  CA 95834 or by telephone at (916) 445-1254 or (800) 952-5210. You may
                  contact us at <a target="_blank" href="mailto:Support@GeoParking.com">Support@GeoParking.com</a> or
                  at GeoParking Technologies Inc. 800 Bellevue Way NE Suite #500
                  Bellevue WA 98004.
                </p>
              </Typography>

            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              className="secondary"
              autoFocus
              onClick={handleClose}
              classes={{ root: "Secondary" }}
            >
              <span>Close</span>
            </Button>
          </DialogActions>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={handleCloseDuplicate}
          aria-labelledby="customized-dialog-title"
          open={openDuplicate}
        >
          <DialogTitle
            dividers
            sx={{ textAlign: "center" }}
            id="customized-dialog-title"
          >
            Terms of Use
          </DialogTitle>

          <DialogContent dividers>
            <Box
              sx={{
                textAlign: "start",
                maxHeight: "50vh",
                overflowY: "auto",
                padding: "0.5rem 5rem",
              }}
            >
              <Typography gutterBottom>
                <h1>Terms of Use</h1>
                <p>Last modified: June 20, 2024</p>

                <section>
                  <h2>Acceptance of the Terms of Use</h2>
                  <p>
                    These terms of use are entered into by and between You and GeoParking
                    Technologies Inc. ("Company", "we", or "us"). The following terms and
                    conditions together with any documents they expressly incorporate by
                    reference (collectively "Terms of Use") govern your access to and use
                    of <a href="https://www.GeoParking.com">https://www.GeoParking.com</a>{" "}
                    including any content, functionality, and services offered on or
                    through{" "}
                    <a href="https://www.GeoParking.com">https://www.GeoParking.com</a>{" "}
                    (the "Website"), whether as a guest or a registered user.
                  </p>
                  <p>
                    Please read the Terms of Use carefully before you start to use the
                    Website. By using the Website or by clicking to accept or agree to the
                    Terms of Use when this option is made available to you, you accept and
                    agree to be bound and abide by these Terms of Use and our Privacy
                    Policy found at <a href="/privacy-policy">Privacy Policy</a>,
                    incorporated herein by reference. If you do not want to agree to these
                    Terms of Use or the Privacy Policy, you must not access or use the
                    Website.
                  </p>
                  <p>
                    This Website is offered and available to users who are 18 years of age
                    or older and reside in the United States, Canada, or any of their
                    territories or possessions. By using this Website, you represent and
                    warrant that you are of legal age to form a binding contract with the
                    Company and meet all of the foregoing eligibility requirements. If you
                    do not meet all of these requirements, you must not access or use the
                    Website.
                  </p>
                </section>

                <section>
                  <h2>Changes to the Terms of Use</h2>
                  <p>
                    We may revise and update these Terms of Use from time to time in our
                    sole discretion. All changes are effective immediately when we post
                    them and apply to all access to and use of the Website thereafter.
                    However, any changes to the dispute resolution provisions set forth in
                    Governing Law and Jurisdiction will not apply to any disputes for
                    which the parties have actual notice on or prior to the date the
                    change is posted on the Website.
                  </p>
                  <p>
                    Your continued use of the Website following the posting of revised
                    Terms of Use means that you accept and agree to the changes. You are
                    expected to check this page from time to time so you are aware of any
                    changes, as they are binding on you.
                  </p>
                </section>

                <section>
                  <h2>Accessing the Website and Account Security</h2>
                  <p>
                    We reserve the right to withdraw or amend this Website and any service
                    or material we provide on the Website in our sole discretion without
                    notice. We will not be liable if for any reason all or any part of the
                    Website is unavailable at any time or for any period. From time to
                    time, we may restrict access to some parts of the Website, or the
                    entire Website, to users, including registered users.
                  </p>
                  <p>You are responsible for:</p>
                  <ul>
                    <li>
                      Making all arrangements necessary for you to have access to the
                      Website.
                    </li>
                    <li>
                      Ensuring that all persons who access the Website through your
                      internet connection are aware of these Terms of Use and comply with
                      them.
                    </li>
                  </ul>
                  <p>
                    To access the Website or some of the resources it offers, you may be
                    asked to provide certain registration details or other information. It
                    is a condition of your use of the Website that all the information you
                    provide on the Website is correct, current, and complete. You agree
                    that all information you provide to register with this Website or
                    otherwise, including, but not limited to, through the use of any
                    interactive features on the Website, is governed by our{" "}
                    <a href="/privacy-policy">Privacy Policy</a> and you consent to all
                    actions we take with respect to your information consistent with our
                    Privacy Policy. If you choose, or are provided with, a username,
                    password, or any other piece of information as part of our security
                    procedures, you must treat such information as confidential, and you
                    must not disclose it to any other person or entity. You also
                    acknowledge that your account is personal to you and agree not to
                    provide any other person with access to this Website or portions of it
                    using your username, password, or other security information. You agree
                    to notify us immediately of any unauthorized access to or use of your
                    username or password or any other breach of security. You also agree
                    to ensure that you exit from your account at the end of each session.
                    You should use particular caution when accessing your account from a
                    public or shared computer so that others are not able to view or
                    record your password or other personal information. We have the right
                    to disable any username, password, or other identifier, whether chosen
                    by you or provided by us, at any time in our sole discretion for any
                    or no reason, including if, in our opinion, you have violated any
                    provision of these Terms of Use.
                  </p>
                </section>

                <section>
                  <h2>Intellectual Property Rights</h2>
                  <p>
                    The Website and its entire contents, features, and functionality
                    (including but not limited to all information, software, text,
                    displays, images, video, and audio, and the design, selection, and
                    arrangement thereof) are owned by the Company, its licensors, or other
                    providers of such material and are protected by United States and
                    international copyright, trademark, patent, trade secret, and other
                    intellectual property or proprietary rights laws.
                  </p>
                  <p>
                    These Terms of Use permit you to use the Website for your personal,
                    non-commercial use only. You must not reproduce, distribute, modify,
                    create derivative works of, publicly display, publicly perform,
                    republish, download, store, or transmit any of the material on our
                    Website, except as follows:
                  </p>
                  <ul>
                    <li>
                      Your computer may temporarily store copies of such materials in RAM
                      incidental to your accessing and viewing those materials.
                    </li>
                    <li>
                      You may store files that are automatically cached by your Web
                      browser for display enhancement purposes.
                    </li>
                    <li>
                      You may print one copy of a reasonable number of pages of the
                      Website for your own personal, non-commercial use and not for
                      further reproduction, publication, or distribution.
                    </li>
                    <li>
                      If we provide desktop, mobile, or other applications for download,
                      you may download a single copy to your computer or mobile device
                      solely for your own personal, non-commercial use, provided you agree
                      to be bound by our end-user license agreement for such applications.
                    </li>
                  </ul>
                  <p>You must not:</p>
                  <ul>
                    <li>Modify copies of any materials from this site.</li>
                    <li>
                      Use any illustrations, photographs, video, or audio sequences or any
                      graphics separately from the accompanying text.
                    </li>
                    <li>
                      Delete or alter any copyright, trademark, or other proprietary rights
                      notices from copies of materials from this site.
                    </li>
                  </ul>
                  <p>
                    You must not access or use for any commercial purposes any part of the
                    Website or any services or materials available through the Website.{" "}
                  </p>
                  <p>
                    If you print, copy, modify, download or otherwise use or provide any
                    other person with access to any part of the Website in breach of the
                    Terms of Use, your right to use the Website will cease immediately and
                    you must, at our option, return or destroy any copies of the materials
                    you have made. No right, title, or interest in or to the Website or any
                    content on the Website is transferred to you, and all rights not
                    expressly granted are reserved by the Company. Any use of the Website
                    not expressly permitted by these Terms of Use is a breach of these
                    Terms of Use and may violate copyright, trademark, and other laws.
                  </p>
                </section>

                <section>
                  <h2>Trademarks</h2>
                  <p>
                    The Company name, the term “GeoParking”, the Company logo, and all
                    related names, logos, product and service names, designs, and slogans
                    are trademarks of the Company or its affiliates or licensors. You must
                    not use such marks without the prior written permission of the
                    Company. All other names, logos, product and service names, designs,
                    and slogans on this Website are the trademarks of their respective
                    owners.
                  </p>
                </section>

                <section>
                  <h2>Prohibited Uses</h2>
                  <p>
                    You may use the Website only for lawful purposes and in accordance
                    with these Terms of Use. You agree not to use the Website:
                  </p>
                  <ul>
                    <li>
                      In any way that violates any applicable federal, state, local, or
                      international law or regulation (including, without limitation, any
                      laws regarding the export of data or software to and from the US or
                      other countries).
                    </li>
                    <li>
                      For the purpose of exploiting, harming, or attempting to exploit or
                      harm minors in any way by exposing them to inappropriate content,
                      asking for personally identifiable information, or otherwise.
                    </li>
                    <li>
                      To send, knowingly receive, upload, download, use, or re-use any
                      material that does not comply with the Content Standard set out
                      below in these Terms of Use.
                    </li>
                    <li>
                      To transmit, or procure the sending of, any advertising or
                      promotional material, including any "junk mail", "chain letter",
                      "spam", or any other similar solicitation.
                    </li>
                    <li>
                      To impersonate or attempt to impersonate the Company, a Company
                      employee, another user, or any other person or entity (including,
                      without limitation, by using e-mail addresses or screen names
                      associated with any of the foregoing).
                    </li>
                    <li>
                      To engage in any other conduct that restricts or inhibits anyone's
                      use or enjoyment of the Website, or which, as determined by us, may
                      harm the Company or users of the Website, or expose them to
                      liability.
                    </li>
                  </ul>
                  <p>Additionally, you agree not to:</p>
                  <ul>
                    <li>
                      Use the Website in any manner that could disable, overburden,
                      damage, or impair the Site or interfere with any other party's use
                      of the Website, including their ability to engage in real-time
                      activities through the Website.
                    </li>
                    <li>
                      Use any robot, spider, or other automatic devices, process, or means
                      to access the Website for any purpose, including monitoring or
                      copying any of the material on the Website.
                    </li>
                    <li>
                      Use any manual process to monitor or copy any of the material on the
                      Website, or for any other purpose not expressly authorized in these
                      Terms of Use, without our prior written consent.
                    </li>
                    <li>
                      Use any device, software, or routine that interferes with the proper
                      working of the Website.
                    </li>
                    <li>
                      Introduce any viruses, trojan horses, worms, logic bombs, or other
                      material which is malicious or technologically harmful.
                    </li>
                    <li>
                      Attempt to gain unauthorized access to, interfere with, damage, or
                      disrupt any parts of the Website, the server on which the Website is
                      stored, or any server, computer, or database connected to the
                      Website.
                    </li>
                    <li>
                      Attack the Website via a denial-of-service attack or a distributed
                      denial-of-service attack.
                    </li>
                    <li>
                      Otherwise attempt to interfere with the proper working of the
                      Website.
                    </li>
                  </ul>
                </section>

                <section>
                  <h2>User Contributions</h2>
                  <p>
                    The Website may contain message boards, chat rooms, personal web pages
                    or profiles, forums, bulletin boards, and other interactive features
                    (collectively, "Interactive Services") that allow users to post,
                    submit, publish, display, or transmit to other users or other persons
                    (hereinafter, "post") content or materials (collectively, "User
                    Contributions") on or through the Website.
                  </p>
                  <p>
                    All User Contributions must comply with the Content Standards set out
                    in these Terms of Use.
                  </p>
                  <p>
                    Any User Contribution you post to the site will be considered
                    non-confidential and non-proprietary. By providing any User
                    Contribution on the Website, you grant us and our affiliates and
                    service providers, and each of their and our respective licensees,
                    successors, and assigns the right to use, reproduce, modify, perform,
                    display, distribute, and otherwise disclose to third parties any such
                    material for any purpose.
                  </p>
                  <p>You represent and warrant that:</p>
                  <ul>
                    <li>
                      You own or control all rights in and to the User Contributions and
                      have the right to grant the license granted above to us and our
                      affiliates and service providers, and each of their and our
                      respective licensees, successors, and assigns.
                    </li>
                    <li>
                      All of your User Contributions do and will comply with these Terms
                      of Use.
                    </li>
                  </ul>
                  <p>
                    You understand and acknowledge that you are responsible for any User
                    Contributions you submit or contribute, and you, not the Company, have
                    full responsibility for such content, including its legality,
                    reliability, accuracy, and appropriateness.
                  </p>
                  <p>
                    We are not responsible or liable to any third party for the content or
                    accuracy of any User Contributions posted by you or any other user of
                    the Website.
                  </p>
                  <h2>Monitoring and Enforcement; Termination</h2>
                  <p>We have the right to:</p>
                  <ul>
                    <li>
                      Remove or refuse to post any User Contributions for any or no reason
                      in our sole discretion.
                    </li>
                    <li>
                      Take any action with respect to any User Contribution that we deem
                      necessary or appropriate in our sole discretion, including if we
                      believe that such User Contribution violates the Terms of Use,
                      including the Content Standards, infringes any intellectual property
                      right or other right of any person or entity, threatens the personal
                      safety of users of the Website or the public, or could create
                      liability for the Company.
                    </li>
                    <li>
                      Disclose your identity or other information about you to any third
                      party who claims that material posted by you violates their rights,
                      including their intellectual property rights or their right to
                      privacy.
                    </li>
                    <li>
                      Take appropriate legal action, including without limitation,
                      referral to law enforcement, for any illegal or unauthorized use of
                      the Website.
                    </li>
                    <li>
                      Terminate or suspend your access to all or part of the Website for
                      any or no reason, including without limitation, any violation of
                      these Terms of Use.
                    </li>
                  </ul>
                  <p>
                    Without limiting the foregoing, we have the right to fully cooperate
                    with any law enforcement authorities or court order requesting or
                    directing us to disclose the identity or other information of anyone
                    posting any materials on or through the Website. YOU WAIVE AND HOLD
                    HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE
                    PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF
                    THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND
                    FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER
                    SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
                  </p>
                  <p>
                    However, we do not undertake to review material before it is posted on
                    the Website, and cannot ensure prompt removal of objectionable
                    material after it has been posted. Accordingly, we assume no liability
                    for any action or inaction regarding transmissions, communications, or
                    content provided by any user or third party. We have no liability or
                    responsibility to anyone for performance or nonperformance of the
                    activities described in this section.
                  </p>
                </section>
                <section>
                  <h2>Content Standards</h2>
                  <p>
                    These content standards apply to any and all User Contributions and
                    use of Interactive Services. User Contributions must in their entirety
                    comply with all applicable federal, state, local, and international
                    laws and regulations. Without limiting the foregoing, User
                    Contributions must not:
                  </p>
                  <ul>
                    <li>
                      Contain any material which is defamatory, obscene, indecent,
                      abusive, offensive, harassing, violent, hateful, inflammatory, or
                      otherwise objectionable.
                    </li>
                    <li>
                      Promote sexually explicit or pornographic material, violence, or
                      discrimination based on race, sex, religion, nationality,
                      disability, sexual orientation, or age.
                    </li>
                    <li>
                      Infringe any patent, trademark, trade secret, copyright, or other
                      intellectual property or other rights of any other person.
                    </li>
                    <li>
                      Violate the legal rights (including the rights of publicity and
                      privacy) of others or contain any material that could give rise to
                      any civil or criminal liability under applicable laws or regulations
                      or that otherwise may be in conflict with these Terms of Use and our{" "}
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                  </ul>
                </section>

                <section>
                  <h2>Reliance on Information Posted</h2>
                  <p>
                    The information presented on or through the Website is made available
                    solely for general information purposes. We do not warrant the
                    accuracy, completeness, or usefulness of this information. Any
                    reliance you place on such information is strictly at your own risk.
                    We disclaim all liability and responsibility arising from any reliance
                    placed on such materials by you or any other visitor to the Website,
                    or by anyone who may be informed of any of its contents.
                  </p>
                  <p>
                    This Website may include content provided by third parties, including
                    materials provided by other users, bloggers, and third-party licensors,
                    syndicators, aggregators, and/or reporting services. All statements
                    and/or opinions expressed in these materials, and all articles and
                    responses to questions and other content, other than the content
                    provided by the Company, are solely the opinions and the
                    responsibility of the person or entity providing those materials.
                    These materials do not necessarily reflect the opinion of the Company.
                    We are not responsible or liable to you or any third party for the
                    content or accuracy of any materials provided by any third parties.
                  </p>
                </section>

                <section>
                  <h2>Changes to the Website</h2>
                  <p>
                    We may update the content on this Website from time to time, but its
                    content is not necessarily complete or up to date. Any of the material
                    on the Website may be out of date at any given time, and we are under
                    no obligation to update such material.
                  </p>
                </section>

                <section>
                  <h2>Information About You and Your Visits to the Website</h2>
                  <p>
                    All information we collect on this Website is subject to our{" "}
                    <a href="/privacy-policy">Privacy Policy</a>. By using the Website,
                    you consent to all actions taken by us with respect to your
                    information in compliance with the Privacy Policy.
                  </p>
                </section>
                <section>
                  <h2>Linking to the Website and Social Media Features</h2>
                  <p>
                    You may link to our homepage, provided you do so in a way that is fair
                    and legal and does not damage our reputation or take advantage of it,
                    but you must not establish a link in such a way as to suggest any form
                    of association, approval, or endorsement on our part without our
                    express written consent.
                  </p>
                  <p>
                    This Website may provide certain social media features that enable you
                    to:
                  </p>
                  <ul>
                    <li>
                      Link from your own or certain third-party websites to certain
                      content on this Website.
                    </li>
                    <li>
                      Send e-mails or other communications with certain content, or links
                      to certain content, on this Website.
                    </li>
                    <li>
                      Cause limited portions of content on this Website to be displayed or
                      appear to be displayed on your own or certain third-party websites.
                    </li>
                  </ul>
                  <p>
                    You may use these features solely as they are provided by us, and
                    solely with respect to the content they are displayed with and
                    otherwise in accordance with any additional terms and conditions we
                    provide with respect to such features. Subject to the foregoing, you
                    must not:
                  </p>
                  <ul>
                    <li>Establish a link from any website that is not owned by you.</li>
                    <li>
                      Cause the Website or portions of it to be displayed on, or appear to
                      be displayed by, any other site, for example, framing, deep linking,
                      or in-line linking.
                    </li>
                    <li>Link to any part of the Website other than the homepage.</li>
                    <li>
                      Otherwise take any action with respect to the materials on this
                      Website that is inconsistent with any other provision of these Terms
                      of Use.
                    </li>
                  </ul>
                  <p>
                    You agree to cooperate with us in causing any unauthorized framing or
                    linking immediately to cease. We reserve the right to withdraw linking
                    permission without notice.
                  </p>{" "}
                  <p>
                    We may disable all or any social media features and any links at any
                    time without notice in our discretion.
                  </p>
                </section>
                <section>
                  <h2>Geographic Restrictions</h2>
                  <p>
                    The owner of the Website is based in the State of Washington in the
                    United States. We provide this Website for use only by persons located
                    in the United States and Canada. We make no claims that the Website or
                    any of its content is accessible or appropriate outside of the United
                    States and Canada. Access to the Website may not be legal by certain
                    persons or in certain countries. If you access the Website from
                    outside the United States or Canada, you do so on your own initiative
                    and are responsible for compliance with local laws.
                  </p>
                </section>

                <section>
                  <h2>Disclaimer of Warranties</h2>
                  <p>
                    You understand that we cannot and do not guarantee or warrant that
                    files available for downloading from the internet or the Website will
                    be free of viruses or other destructive code. You are responsible for
                    implementing sufficient procedures and checkpoints to satisfy your
                    particular requirements for anti-virus protection and accuracy of data
                    input and output, and for maintaining a means external to our site for
                    any reconstruction of any lost data.
                  </p>
                  <p>
                    YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
                    OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
                    CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
                    PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
                    WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
                    NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
                    REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
                    RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE.
                    WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
                    ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE,
                    ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
                    WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
                    WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
                    ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE
                    OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE
                    MEET YOUR NEEDS OR EXPECTATIONS.
                  </p>
                  <p>
                    THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                    EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED
                    TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS
                    FOR PARTICULAR PURPOSE.
                  </p>
                  <p>
                    THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
                    OR LIMITED UNDER APPLICABLE LAW.
                  </p>
                </section>

                <section>
                  <h2>Limitation on Liability</h2>
                  <p>
                    TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COLLECTIVE
                    LIABILITY OF THE COMPANY AND ITS SUBSIDIARIES AND AFFILIATES, AND
                    THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND
                    DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN
                    CONTRACT, TORT, OR OTHERWISE) EXCEED THE GREATER OF $500 USD OR THE
                    AMOUNT YOU HAVE PAID TO THE COMPANY FOR THE APPLICABLE CONTENT,
                    PRODUCT, OR SERVICE IN THE LAST 12 MONTHS OUT OF WHICH LIABILITY
                    AROSE.
                  </p>
                  <p>
                    The limitation of liability set out above does not apply to liability
                    resulting from our gross negligence or willful misconduct.
                  </p>
                  <p>
                    THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
                    OR LIMITED UNDER APPLICABLE LAW.
                  </p>
                </section>

                <section>
                  <h2>Indemnification</h2>
                  <p>
                    You agree to defend, indemnify, and hold harmless the Company, its
                    affiliates, licensors, and service providers, and its and their
                    respective officers, directors, employees, contractors, agents,
                    licensors, suppliers, successors, and assigns from and against any
                    claims, liabilities, damages, judgments, awards, losses, costs,
                    expenses, or fees (including reasonable attorneys' fees) arising out
                    of or relating to your violation of these Terms of Use or your use of
                    the Website, including, but not limited to, your User Contributions,
                    any use of the Website's content, services, and products other than as
                    expressly authorized in these Terms of Use, or your use of any
                    information obtained from the Website.
                  </p>
                </section>

                <section>
                  <h2>Governing Law and Jurisdiction</h2>
                  <p>
                    All matters relating to the Website and these Terms of Use and any
                    dispute or claim arising therefrom or related thereto (in each case,
                    including non-contractual disputes or claims) shall be governed by and
                    construed in accordance with the internal laws of the United States of
                    America and the State of Washington without giving effect to any
                    choice or conflict of law provision or rule (whether of the State of
                    Washington or any other jurisdiction).
                  </p>
                  <p>
                    Any legal suit, action, or proceeding arising out of, or related to,
                    these Terms of Use or the Website shall be instituted exclusively in
                    the federal courts of the United States or the courts of the State of
                    Washington in each case located in the City of Seattle and County of
                    King, although we retain the right to bring any suit, action, or
                    proceeding against you for breach of these Terms of Use in your
                    country of residence or any other relevant country. You waive any and
                    all objections to the exercise of jurisdiction over you by such courts
                    and to venue in such courts.
                  </p>
                </section>

                <section>
                  <h2>Arbitration</h2>
                  <p>
                    At Company's sole discretion, it may require You to submit any
                    disputes arising from these Terms of Use or use of the Website,
                    including disputes arising from or concerning their interpretation,
                    violation, invalidity, non-performance, or termination, to final and
                    binding arbitration under the Rules of Arbitration of the American
                    Arbitration Association applying Washington law.
                  </p>
                </section>

                <section>
                  <h2>Waiver and Severability</h2>
                  <p>
                    No waiver by the Company of any term or condition set forth in
                    these Terms of Use shall be deemed a further or continuing waiver of
                    such term or condition or a waiver of any other term or condition, and
                    any failure of the Company to assert a right or provision under these
                    Terms of Use shall not constitute a waiver of such right or provision.
                  </p>
                  <p>
                    If any provision of these Terms of Use is held by a court or other
                    tribunal of competent jurisdiction to be invalid, illegal, or
                    unenforceable for any reason, such provision shall be eliminated or
                    limited to the minimum extent such that the remaining provisions of
                    the Terms of Use will continue in full force and effect.
                  </p>
                </section>

                <section>
                  <h2>Entire Agreement</h2>
                  <p>
                    The Terms of Use, our Privacy Policy, and Terms of Sale constitute the
                    sole and entire agreement between you and GeoParking Technologies Inc.
                    with respect to the Website and supersede all prior and
                    contemporaneous understandings, agreements, representations, and
                    warranties, both written and oral, with respect to the Website.
                  </p>
                </section>

                <section>
                  <h2>Your Comments and Concerns</h2>
                  <p>
                    This website is operated by GeoParking Technologies Inc., 800 Bellevue
                    Way NE, Suite #500, Bellevue, Washington 98004.
                  </p>
                  <p>
                    All other feedback, comments, requests for technical support, and
                    other communications relating to the Website should be directed to:{" "}
                    <a href="mailto:Support@GeoParking.com">Support@GeoParking.com</a>.
                  </p>
                </section>
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              className="secondary"
              autoFocus
              onClick={handleCloseDuplicate}
              classes={{ root: "Secondary" }}
            >
              <span>Close</span>
            </Button>
          </DialogActions>
        </BootstrapDialog>


      </div>
    </div>
  );
};

export default AccountInformation;
