export const getPasswordRequirements = (password) => {
  return {
    minLength: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    digit: /\d/.test(password),
    specialChar: /[!@#$%^&*]/.test(password),
    noSpaces: !/\s/.test(password),
  };
};
