// Scheduler.jsx
import React, { useState, useCallback, useEffect } from 'react';
import CalendarComponent from './CalendarComponent.jsx';
import AvailabilityModal from './AvailabilityModal.jsx';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { addDays, addWeeks, addMonths, format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import EditChoiceModal from './EditChoiceModal.jsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Scheduler = (props) => {
  const {
    overrides,
    handleradiobutton,
    selectedDate,
    handleNavigate,
    logEvents,
    selectedValue,
    timeValues,
    errors,
    handleSave,
    isSaveDisabled,
    handleChange,
    handleStepsback,
    handleSteps,
    bankdetails,
    EmailId,
    handlebankaccountdetails,
    timeSlotesAvailability,
    toggleCondition,
    events,
    setEvents,
    newEventTriggered,
    ...rest
  } = props;
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [validatedEvents, setValidatedEvents] = useState([]);
  const [editMode, setEditMode] = useState('single');
  // Scheduler.jsx
  const [showEditChoiceModal, setShowEditChoiceModal] = useState(false);


  const handleEventsValidated = useCallback((validatedEvents) => {
    setValidatedEvents(validatedEvents);
  }, []);

  // Scheduler.jsx

  useEffect(() => {
    console.log('Scheduler - events before conversion to calendarEvents:', events);

    const convertedEvents = events.map((event) => ({
      ...event,
      start: new Date(event.Start || event.start), // Interpreted as local time
      end: new Date(event.End || event.end),
      title: event.Title || event.title,
      id: event.Id || event.id,
      RecurrenceId: event.RecurrenceId || event.recurrenceId,

    }));

    console.log('Scheduler - calendarEvents after conversion:', convertedEvents);
    setCalendarEvents(convertedEvents);
  }, [events]);







  const handleAddEvent = (start) => {
    setSelectedEvent({
      Id: uuidv4(),
      Title: '',
      Start: start,
      End: new Date(start.getTime() + 60 * 60 * 1000),
      AllDay: false,
      Recurrence: null,
      RecurrenceId: uuidv4(),
      SelectedDays: [],
      EndType: 'never',
      EndDateOn: null,
    });
    setIsEditing(false);
    setShowModal(true);
  };


  const handleNextButtonClick = () => {
    // Validate time slot availability
    timeSlotesAvailability();
    // Move to the next step
    toggleCondition('4 of 5 - Enter Bank Details');
    toggleCondition('3 of 5 - Schedule Availability');
  };
  const eventsOverlap = (event1, event2) => {
    const start1 = new Date(event1.Start || event1.start);
    const end1 = new Date(event1.End || event1.end);
    const start2 = new Date(event2.Start || event2.start);
    const end2 = new Date(event2.End || event2.end);

    return start1 < end2 && end1 > start2;
  };


  const handleBackButtonClick = () => {
    props.handleConfirm(false);
    toggleCondition('2 of 5 - Mark Parking Spaces & Other Areas');
    toggleCondition('3 of 5 - Schedule Availability');
  };

  // Scheduler.jsx
  const handleSelectEvent = (event) => {
    if (event.RecurrenceId) {
      // Event is part of a recurring series
      setSelectedEvent(event);
      setShowEditChoiceModal(true);
    } else {
      setEditMode('single');
      setSelectedEvent(event);
      setIsEditing(true);
      setShowModal(true);
    }
  };

  // Scheduler.jsx
  const handleEditChoice = (mode) => {
    setEditMode(mode);
    setIsEditing(true);
    setShowModal(true);
    setShowEditChoiceModal(false);
  };

  const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  const WEEKENDS = ['Sat', 'Sun'];
  const handleSaveEvent = (newEvent, recurrenceChanged = false) => {
    console.log('Scheduler - Event received from AvailabilityModal:', newEvent);

    const savedEvent = { ...newEvent };

    const eventStart = new Date(savedEvent.Start);
    const now = new Date();

    if (eventStart < now - 1) {
      alert("Cannot save events in the past.");
      return;
    }

    // Assign a unique Id if it doesn't exist
    savedEvent.Id = savedEvent.Id || uuidv4();

    let newEvents = [];
    let existingEvents = events;

    if (savedEvent.Recurrence) {
      // Assign a shared RecurrenceId if it doesn't exist
      savedEvent.RecurrenceId = savedEvent.RecurrenceId || uuidv4();
      if (isEditing) {
        if (editMode === 'series') {
          // Remove old series events
          existingEvents = existingEvents.filter(
            (event) => event.RecurrenceId !== selectedEvent.RecurrenceId
          );
          // Generate new series events
          savedEvent.RecurrenceId = selectedEvent.RecurrenceId;
          newEvents = generateRecurringEvents(savedEvent);
        } else if (editMode === 'single') {
          // Remove the selected occurrence
          existingEvents = existingEvents.filter(
            (event) => event.Id !== selectedEvent.Id
          );
          // Add the modified single event
          savedEvent.RecurrenceId = null; // Break from series
          newEvents = [savedEvent];
        }
      } else {
        // Adding a new event
        if (savedEvent.Recurrence) {
          savedEvent.RecurrenceId = savedEvent.RecurrenceId || uuidv4();
          newEvents = generateRecurringEvents(savedEvent);
        } else {
          newEvents = [savedEvent];
        }
      }

      // Determine the type of recurrence and generate events accordingly
      let recurringEvents = [];
      switch (savedEvent.Recurrence) {
        case 'custom':
          if (savedEvent.CustomRecurrenceType === 'week') {
            // Custom Weekly Recurrence
            const duration = new Date(savedEvent.End) - new Date(savedEvent.Start);
            if (isNaN(duration)) {
              console.error('Invalid duration:', savedEvent.End, '-', savedEvent.Start);
              alert("Invalid duration between Start and End times.");
              return;
            }
            recurringEvents = generateCustomRecurringEvents(
              savedEvent,
              savedEvent.Start,
              duration,
              savedEvent.EndAfter,
              savedEvent.EndDateOn
            );
          } else {
            // Handle other custom recurrence types if any
            recurringEvents = generateRecurringEvents(savedEvent);
          }
          break;
        case 'weekdays':
          // Every Weekday (Monday to Friday)
          const durationWD = new Date(savedEvent.End) - new Date(savedEvent.Start);
          if (isNaN(durationWD)) {
            console.error('Invalid duration:', savedEvent.End, '-', savedEvent.Start);
            alert("Invalid duration between Start and End times.");
            return;
          }
          recurringEvents = generateCustomRecurringEvents(
            {
              ...savedEvent,
              selectedDays: WEEKDAYS // Use predefined weekdays array
            },
            savedEvent.Start,
            durationWD,
            savedEvent.EndAfter,
            savedEvent.EndDateOn
          );
          break;
        case 'weekends':
          // Every Weekend (Saturday and Sunday)
          const durationWE = new Date(savedEvent.End) - new Date(savedEvent.Start);
          if (isNaN(durationWE)) {
            console.error('Invalid duration:', savedEvent.End, '-', savedEvent.Start);
            alert("Invalid duration between Start and End times.");
            return;
          }
          recurringEvents = generateCustomRecurringEvents(
            {
              ...savedEvent,
              selectedDays: WEEKENDS // Use predefined weekends array
            },
            savedEvent.Start,
            durationWE,
            savedEvent.EndAfter,
            savedEvent.EndDateOn
          );
          break;
        case 'daily':
        case 'weekly':
        case 'bi-weekly':
        case 'monthly':
        case 'yearly':
          // Standard Recurrence Types
          recurringEvents = generateRecurringEvents(savedEvent);
          break;
        default:
          console.warn(`Unsupported recurrence type: ${savedEvent.Recurrence}`);
          break;
      }

      console.log('Generated Recurring Events:', recurringEvents);

      newEvents = recurringEvents;
    } else {
      if (isEditing) {
        // Exclude the existing singular event from existingEvents
        existingEvents = existingEvents.filter(
          (event) => event.Id !== selectedEvent.Id
        );
      }
      // Add the new singular event to newEvents array
      newEvents = [savedEvent];
    }

    // Check for overlaps before saving
    const hasOverlap = newEvents.some((newEvent) =>
      existingEvents.some((existingEvent) => eventsOverlap(newEvent, existingEvent))
    );

    if (hasOverlap) {
      alert('Cannot save events that overlap with existing events.');
      return;
    }

    // Proceed to add events to state
    setEvents([...existingEvents, ...newEvents]);
    newEventTriggered(newEvent)

    handleCloseModal();
  };




  // Scheduler.jsx
  const handleDeleteEvent = (event) => {
    if (editMode === 'series') {
      // Delete entire series
      setEvents((prevEvents) => prevEvents.filter((e) => e.RecurrenceId !== event.RecurrenceId));
    } else {
      // Delete single occurrence
      setEvents((prevEvents) => prevEvents.filter((e) => e.Id !== event.Id));
    }
    handleCloseModal();
  };





  const handleCloseModal = () => {
    setShowModal(false);
    setIsEditing(false);
    setSelectedEvent(null);
    setEditMode('single');
  };

  // Recurrence Generation Functions
  const generateCustomRecurringEvents = (event, startDate, duration, occurrences, endDateOn) => {
    const events = [];
    let currentDate = new Date(startDate);
    let generatedOccurrences = 0;

    let selectedDays = event.selectedDays && event.selectedDays.length > 0
      ? event.selectedDays
      : [format(new Date(startDate), 'EEE')]; // Changed from SelectedDays to selectedDays

    if (event.Recurrence === 'custom' && selectedDays.length === 0) {
      console.error('No days selected for custom recurrence.');
      return events; // Early exit to prevent processing
    }

    const recurrenceIntervalWeeks = parseInt(event.CustomRecurrenceCount, 10) || 1;

    console.log('Initial Date:', currentDate);
    console.log('Recurrence Interval (weeks):', recurrenceIntervalWeeks);
    console.log('Selected Days (for weekly):', selectedDays);
    console.log('End Date On:', endDateOn);

    if (isNaN(currentDate.getTime())) {
      console.error('Invalid startDate:', startDate);
      return events;
    }

    // Continue until we reach the number of occurrences or endDateOn
    while ((!occurrences || generatedOccurrences < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))) {

      selectedDays.forEach((day) => {
        const dayIndex = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(day);
        if (dayIndex === -1) {
          console.error(`Invalid day in SelectedDays: ${day}`);
          return;
        }

        // Calculate the date for the selected day in the current week
        const eventDate = new Date(currentDate);
        eventDate.setDate(currentDate.getDate() + (dayIndex - currentDate.getDay() + 7) % 7);

        // Validate eventDate
        if (isNaN(eventDate.getTime())) {
          console.error('Invalid eventDate calculated:', eventDate);
          return;
        }

        // If the eventDate is before the startDate, skip
        if (eventDate < startDate) return;

        // Check if eventDate is within the end condition
        if (endDateOn && eventDate > new Date(endDateOn)) return;

        // Avoid duplicate events on the same date
        const eventStartString = formatLocalDateTime(eventDate);
        if (!events.some(e => e.Start === eventStartString)) {
          console.log('Adding custom weekly event for date:', eventDate);
          events.push({
            ...event,
            Id: uuidv4(),
            RecurrenceId: event.RecurrenceId,
            Start: formatLocalDateTime(eventDate),
            End: formatLocalDateTime(new Date(eventDate.getTime() + duration)),
          });
          generatedOccurrences++;
        }
      });

      // Move to the next recurrence interval (next week)
      currentDate = addWeeks(currentDate, recurrenceIntervalWeeks);
      console.log(`Next Date based on Weekly Interval of ${recurrenceIntervalWeeks} weeks:`, currentDate);

      if (isNaN(currentDate.getTime())) {
        console.error('Invalid currentDate during recurrence generation:', currentDate);
        break;
      }
    }

    console.log('Generated custom recurring events:', events);
    return events;
  };



  const generateWeeklyRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn,
    interval = 1
  ) => {
    console.log('Generating weekly recurring events:', event);
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    const selectedDays = event.SelectedDays.length
      ? event.SelectedDays
      : [format(currentDate, 'EEE')];

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      console.log(
        'Checking for valid day:',
        format(currentDate, 'EEE'),
        'with selected days:',
        selectedDays
      );
      if (selectedDays.includes(format(currentDate, 'EEE'))) {
        console.log('Adding event for date:', currentDate);
        events.push({
          ...event,
          start: new Date(currentDate),
          end: new Date(currentDate.getTime() + duration),
        });
        i++;
      }

      currentDate = calculateNextWeeklyDate(currentDate, interval);
    }

    return events;
  };

  const generateMonthlyRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) => {
    console.log('Generating monthly recurring events:', event);
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      console.log('Adding event for date:', currentDate);
      events.push({
        ...event,
        start: new Date(currentDate),
        end: new Date(currentDate.getTime() + duration),
      });

      currentDate = calculateNextMonthlyDate(
        currentDate,
        event.CustomRecurrenceCount || 1
      );
      i++;
    }

    return events;
  };

  const generateDailyRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) => {
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    const recurrenceInterval = event.CustomRecurrenceCount || 1;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      events.push({
        ...event,
        start: new Date(currentDate),
        end: new Date(currentDate.getTime() + duration),
      });

      i++;
      currentDate = addDays(currentDate, recurrenceInterval);
    }

    return events;
  };

  const generateWeekdayRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn,
    interval = 1
  ) => {
    console.log('Generating weekday recurring events:', event);
    const events = [];
    let currentDate = new Date(startDate);
    let generatedOccurrences = 0;

    while ((!occurrences || generatedOccurrences < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) { // Monday to Friday
        console.log('Adding weekday event for date:', currentDate);
        events.push({
          ...event,
          Id: uuidv4(),
          RecurrenceId: event.RecurrenceId,
          Start: formatLocalDateTime(currentDate),
          End: formatLocalDateTime(new Date(currentDate.getTime() + duration)),
        });
        generatedOccurrences++;
      }
      currentDate = addWeeks(currentDate, interval);
    }

    return events;
  };

  const generateWeekendRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn,
    interval = 1
  ) => {
    console.log('Generating weekend recurring events:', event);
    const events = [];
    let currentDate = new Date(startDate);
    let generatedOccurrences = 0;

    while ((!occurrences || generatedOccurrences < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek === 0 || dayOfWeek === 6) { // Sunday or Saturday
        console.log('Adding weekend event for date:', currentDate);
        events.push({
          ...event,
          Id: uuidv4(),
          RecurrenceId: event.RecurrenceId,
          Start: formatLocalDateTime(currentDate),
          End: formatLocalDateTime(new Date(currentDate.getTime() + duration)),
        });
        generatedOccurrences++;
      }
      currentDate = addWeeks(currentDate, interval);
    }

    return events;
  };


  // Helper methods
  const calculateNextWeeklyDate = (currentDate, interval) => {
    let nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + interval * 7);
    return nextDate;
  };

  const calculateNextMonthlyDate = (currentDate, interval) => {
    let nextDate = new Date(currentDate);
    nextDate.setMonth(nextDate.getMonth() + interval);
    return nextDate;
  };

  // Function to calculate recurrence interval in days
  const recurrenceInterval = (event) => {
    switch (event.Recurrence) {
      case 'daily':
        return 1;
      case 'weekly':
        return 7;
      case 'bi-weekly':
        return 14;
      case 'monthly':
        return 0; // Months handled separately
      case 'yearly':
        return 0; // Years handled separately
      case 'custom':
        const interval = event.CustomRecurrenceCount || 1;
        switch (event.CustomRecurrenceType) {
          case 'day':
            return interval;
          case 'week':
            return interval * 7;
          case 'month':
            return 0; // Months handled separately
          case 'year':
            return 0; // Years handled separately
          default:
            return 1;
        }
      default:
        return 1;
    }
  };

  // Function to get the next occurrence date
  const getNextOccurrence = (date, event) => {
    const nextDate = new Date(date);
    let interval = 1; // Default interval for standard recurrence types
  
    if (event.Recurrence === 'custom') {
      interval = parseInt(event.CustomRecurrenceCount, 10) || 1;
    }
  
    switch (event.Recurrence) {
      case 'daily':
        nextDate.setDate(nextDate.getDate() + interval);
        break;
      case 'weekly':
        nextDate.setDate(nextDate.getDate() + 7 * interval);
        break;
      case 'bi-weekly':
        nextDate.setDate(nextDate.getDate() + 14 * interval);
        break;
      case 'monthly':
        nextDate.setMonth(nextDate.getMonth() + interval);
        break;
      case 'yearly':
        nextDate.setFullYear(nextDate.getFullYear() + interval);
        break;
      case 'custom':
        switch (event.CustomRecurrenceType) {
          case 'day':
            nextDate.setDate(nextDate.getDate() + interval);
            break;
          case 'week':
            nextDate.setDate(nextDate.getDate() + 7 * interval);
            break;
          case 'month':
            nextDate.setMonth(nextDate.getMonth() + interval);
            break;
          case 'year':
            nextDate.setFullYear(nextDate.getFullYear() + interval);
            break;
          default:
            nextDate.setDate(nextDate.getDate() + 1);
            break;
        }
        break;
      default:
        nextDate.setDate(nextDate.getDate() + 1);
        break;
    }
    return nextDate;
  };
  

  // Function to format local date and time as a string
  const formatLocalDateTime = (date) => {
    const year = date.getFullYear();
    const month = (`${date.getMonth() + 1}`).padStart(2, '0');
    const day = (`${date.getDate()}`).padStart(2, '0');
    const hours = (`${date.getHours()}`).padStart(2, '0');
    const minutes = (`${date.getMinutes()}`).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };



  const generateRecurringEvents = (event) => {
    const events = [];
    const startDateTime = new Date(event.Start); // Local time
    const endDateTime = new Date(event.End);     // Local time
    const duration = endDateTime - startDateTime;

    let currentDate = new Date(startDateTime);
    const recurrenceId = event.RecurrenceId || uuidv4();

    // Determine the end condition based on EndType and EndAfter
    let endConditionDate = null;
    let interval = 1; // Default interval for standard recurrence types

    // For custom recurrence, use the custom interval
    if (event.Recurrence === 'custom') {
      interval = parseInt(event.CustomRecurrenceCount, 10) || 1;
    }

    let maxOccurrences = Infinity; // Default: no limit

    if (event.EndType === 'on' && event.EndDateOn) {
      endConditionDate = new Date(event.EndDateOn);
    } else if (event.EndType === 'after' && event.EndAfter) {
      const occurrences = parseInt(event.EndAfter, 10) || 1;
      maxOccurrences = occurrences;

      endConditionDate = new Date(startDateTime);

      switch (event.Recurrence) {
        case 'yearly':
          endConditionDate.setFullYear(endConditionDate.getFullYear() + (occurrences - 1) * interval);
          break;
        case 'monthly':
          endConditionDate.setMonth(endConditionDate.getMonth() + (occurrences - 1) * interval);
          break;
        case 'bi-weekly':
          endConditionDate.setDate(endConditionDate.getDate() + 14 * (occurrences - 1) * interval);
          break;
        case 'weekly':
          endConditionDate.setDate(endConditionDate.getDate() + 7 * (occurrences - 1) * interval);
          break;
        case 'daily':
          endConditionDate.setDate(endConditionDate.getDate() + (occurrences - 1) * interval);
          break;
        case 'custom':
          // Handle custom recurrence end date based on custom recurrence type
          switch (event.CustomRecurrenceType) {
            case 'year':
              endConditionDate.setFullYear(endConditionDate.getFullYear() + (occurrences - 1) * interval);
              break;
            case 'month':
              endConditionDate.setMonth(endConditionDate.getMonth() + (occurrences - 1) * interval);
              break;
            case 'week':
              endConditionDate.setDate(endConditionDate.getDate() + 7 * (occurrences - 1) * interval);
              break;
            case 'day':
            default:
              endConditionDate.setDate(endConditionDate.getDate() + (occurrences - 1) * interval);
              break;
          }
          break;
        default:
          endConditionDate.setDate(endConditionDate.getDate() + (occurrences - 1) * interval);
          break;
      }
    } else {
      // Default end condition when EndType is 'never'
      endConditionDate = new Date(startDateTime);
      if (event.Recurrence === 'yearly') {
        endConditionDate.setFullYear(endConditionDate.getFullYear() + 10);
      } else if (event.Recurrence === 'bi-weekly') {
        endConditionDate.setDate(endConditionDate.getDate() + 14 * 26); // Approx one year
      } else {
        // For other recurrence types, default to one year later
        endConditionDate.setFullYear(endConditionDate.getFullYear() + 1);
      }
    }

    let generatedOccurrences = 0;

    // Generate recurring events based on the recurrence type
    while ((generatedOccurrences < maxOccurrences) && (currentDate <= endConditionDate)) {
      const newEvent = {
        ...event,
        Id: uuidv4(), // Assign a unique Id
        RecurrenceId: recurrenceId, // Assign shared RecurrenceId
        Start: formatLocalDateTime(currentDate),
        End: formatLocalDateTime(new Date(currentDate.getTime() + duration)),
      };
      events.push(newEvent);
      generatedOccurrences++;

      // Move to next occurrence based on recurrence type
      currentDate = getNextOccurrence(currentDate, event);
    }

    return events;
  };





  const handleOpenModal = () => {
    // Set default date to today
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth(); // Note: month is zero-based
    const date = today.getDate();

    // Create Date objects for 12:00 PM and 1:00 PM
    const startTime = new Date(year, month, date, 12, 0, 0); // 12:00 PM
    const endTime = new Date(year, month, date, 13, 0, 0);   // 1:00 PM

    setSelectedEvent({
      Id: uuidv4(),
      Title: '',
      Start: startTime.toISOString(), // Set default Start time
      End: endTime.toISOString(),     // Set default End time
      AllDay: false,
      Recurrence: null,
      RecurrenceId: uuidv4(),
      selectedDays: [],
      EndType: 'never',
      EndDateOn: null,
    });
    setIsEditing(false);
    setShowModal(true);
  };


  const backButtonClick = () => {
    props.handleConfirm(false);
    toggleCondition('2 of 5 - Mark Pick up and Drop off Area');
    toggleCondition('3 of 5 - Schedule Availability');
  };

  return (
    <div className="Scheduler">
      <ToastContainer />
      <CalendarComponent
        events={calendarEvents}
        onSelectEvent={handleSelectEvent}
        onAddEvent={handleAddEvent}
        onOpenModal={() => setShowModal(true)}
        onEventsValidated={handleEventsValidated}
        selectedDate={selectedDate}
        onNavigate={handleNavigate}
      />
      <div className="mb-3">
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          style={{ marginTop: '2vh' }}
        >
          Robotaxi parking day(s)/time(s) not allowed
        </Button>
      </div>
      <EditChoiceModal
        show={showEditChoiceModal}
        onClose={() => setShowEditChoiceModal(false)}
        onEditChoice={handleEditChoice}
      />

      <AvailabilityModal
        show={showModal}
        onClose={handleCloseModal}
        onSave={handleSaveEvent}
        onDelete={handleDeleteEvent}
        isEditing={isEditing}
        editMode={editMode}
        eventDetails={
          selectedEvent || {
            Title: '',
            Start: '',
            End: '',
            Recurrence: '',
            AllDay: false,
            RecurrenceId: null,

          }
        }
      />
      <div style={{ textAlign: 'end', marginTop: '40px ', marginRight: '7vw' }}>
        <div
          className="back-wrapper"
          style={{
            width: '100px',
            backgroundColor: '#fff',
            boxShadow: '0 0 3px 2px #cec7c759',
          }}
          onClick={handleBackButtonClick}
        >
          <b className="next">Go Back</b>
        </div>
        <div
          className="next-wrapper"
          onClick={handleNextButtonClick}
          style={{

            backgroundColor: '#fff',
            boxShadow: '0 0 3px 2px #cec7c759',
          }}
        >
          <b className="next">Next</b>
        </div>
        <div
          className="autosave1-wrapper"
          onClick={(e) => handleSave(e)}
          style={{
            pointerEvents: isSaveDisabled ? 'none' : 'auto',
            opacity: isSaveDisabled ? 0.5 : 1,
            marginRight: '30px',
            backgroundColor: '#6ACD54',
            boxShadow: '0 0 3px 2px #cec7c759',

          }}
        >
          <b className="next">Save</b>

        </div>

      </div>
    </div>
  );
};

export default Scheduler;
