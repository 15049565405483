// client/src/components/SearchBox.js

import React, { useState, useRef, useEffect } from "react";
import { TextField, List, ListItem, ListItemText, Paper } from "@mui/material";
import "./SearchBox.css";

const SearchBox = ({ onLocationSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const subscriptionKey =
    "EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG";
  const searchBoxRef = useRef(null); // Ref for detecting clicks outside

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 2) {
      // Trigger search after typing at least 3 characters
      try {
        const response = await fetch(
          `https://atlas.microsoft.com/search/fuzzy/json?api-version=1.0&query=${encodeURIComponent(
            query
          )}&subscription-key=${subscriptionKey}`
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching search results: ${response.statusText}`
          );
        }

        const data = await response.json();
        setSuggestions(data.results);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    console.log("handleSuggestionClick invoked");
    console.log("Selected suggestion:", suggestion);
    console.log("Suggestion address:", suggestion.address); // Added debug statement

    // Extract the freeform address
    const freeformAddress = suggestion.address.freeformAddress || "";

    // Improved logic to determine if it's a street address or a city
    // Assumes street addresses start with a number
    const isStreetAddress = /^\d+/.test(freeformAddress.trim());
    const isCity = suggestion.address.city !== undefined && !isStreetAddress;

    // Construct propertyData with a 'type' field
    const propertyData = {
      PropertyAddress: freeformAddress,
      type: isStreetAddress ? "street" : isCity ? "city" : "unknown",
      // Add other relevant fields from the suggestion as needed
      // Example:
      // StatusReason: "1",
      // Status: "1",
      // garage: "True",
      // GatedOrFenced: "False",
      // Fee: "True",
      // REIT: "False",
      // ... etc.
    };

    console.log("Constructed propertyData:", propertyData);

    // Pass both position and propertyData to the parent component
    onLocationSelect(suggestion.position, propertyData);

    // Update search box with selected location
    setSearchTerm(freeformAddress);

    // Clear suggestions
    setSuggestions([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Prevent form submission if within a form
      event.preventDefault();
      // Clear suggestions on Enter press
      setSuggestions([]);
    }
  };

  // Close suggestions when clicking outside of the search box
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target)
      ) {
        setSuggestions([]); // Clear suggestions on outside click
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="search-box-container" ref={searchBoxRef}>
      <TextField
        label="Search location"
        variant="filled"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown} // Attach the Enter key handler

      />
      {suggestions.length > 0 && (
        <Paper className="suggestions-list">
          <List>
            {suggestions.map((suggestion, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <ListItemText primary={suggestion.address.freeformAddress} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
};

export default SearchBox;
