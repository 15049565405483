import React, { useEffect, useState } from "react";
import RobotaxiLogin from "../../Images/LoginImage.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from  '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { encryptPassword, decryptPassword } from "../utils/EncryptDecrypt";
import CryptoJS from "crypto-js";

const ForgetPassword = () => {
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
  const navigate = useNavigate();
  const [EmailId, setEmailId] = useState({});
  const [EmailForRestPasswordSuccess, sendingEmailForRestPassword] =
    useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    Password: "",
    ConfirmPassword: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });

useEffect(() => {
  const searchParams = new URLSearchParams(window.location.search);
  let emailIdParam = searchParams.get("email"); 
  if (emailIdParam) {
    emailIdParam = emailIdParam.replace(/ /g, "+");
    const emailId = decryptPassword(emailIdParam.trim());
    setEmailId(emailId);
  }
}, []);
  
  const validatePasswordForm = () => {
    const errors = {};
    if (!formData.Password) {
      errors.Password = "Password is required";
    } else if (!validatePassword(formData.Password)) {
      errors.Password =
        "Passwords requirements are not satisified: ";
    }

    if (!formData.ConfirmPassword) {
      errors.ConfirmPassword = "Confirm Password is required";
    } else if (formData.Password !== formData.ConfirmPassword) {
      errors.ConfirmPassword = "Please ensure the Passwords match ";
    }
 
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const ChangePasswordButtonClick = async (e) => {
    e.preventDefault();
    if (validatePasswordForm()) {
      let resetEmail = {
        EmailId: EmailId,
        TypeOfEmail: "resetpasswordsuccess",
        DomainName: window.location.origin,
      };
      let EmailPassword = {
        EmailId: EmailId,
        Password: formData.Password,
      };

      try {
        const response = await axios.post(
          `${hostPath}/api/ForgetPassword`,
          EmailPassword,
          {
            headers: { "x-functions-key": `${hostHeader}` },
          }
        );
        console.log("response.dataapi/ForgetPassword", response.data);
        if (response.data.status === true) {
          console.log("response.data", response.data);
          // navigate('/dashboard');
          sendingEmailForRestPassword(true);

          try {
            const response = await axios.post(
              `${hostPath}/api/SendEmailConfirmation`,
              resetEmail,
              {
                headers: { "x-functions-key": `${hostHeader}` },
              }
            );

            if (response.data) {
              navigate("/");
            } else {
              setErrors({
                ...errors,
                Password: "Please enter the correct password and try again!",
              });
            }
          } catch (error) {
            console.error("Error:", error);
          }
        } else {
          setErrors({ ...errors, Password: "Something went wrong please try again" });
        }
      } catch (error) {
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    clearError(name); // Clear error for the corresponding input field
    if (name === "Password") {
      validatePassword(value);
    }
  };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => {
      return { ...prevErrors, [fieldName]: "" };
    });
  };

  const accountinfopage = () => {
    navigate("/");
  };

  const validatePassword = (password) => {
    const requirements = {
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
    };
    setPasswordRequirements(requirements); 
    const allRequirementsMet = Object.values(requirements).every(requirement => requirement);
    return allRequirementsMet; 
};

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-md-7 p-0">
          <img src={RobotaxiLogin} alt="Login" className="GeoparkingImage" />
        </div>
        <div className="col-md-5 d-flex align-items-center justify-content-center">
          {EmailForRestPasswordSuccess ? (
            <div className="login-form loginfirst">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="160"
                  height="160"
                  viewBox="0 0 160 160"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M80.0926 145.799C43.9006 120.43 24.0286 88.2168 24.0286 54.9155V39.6693L80.0926 14.7271L136.157 39.6693V54.9155C136.157 88.2168 116.285 120.43 80.0926 145.799Z"
                    fill="#E6E9ED"
                  />
                  <path
                    d="M80.0925 99.1928C95.368 99.1928 107.751 86.8096 107.751 71.5342C107.751 56.2587 95.368 43.8755 80.0925 43.8755C64.817 43.8755 52.4338 56.2587 52.4338 71.5342C52.4338 86.8096 64.817 99.1928 80.0925 99.1928Z"
                    fill="#82D65F"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M75.0615 83.6622C74.4997 83.6622 73.9628 83.4382 73.5682 83.0435L65.0881 74.5635C64.2633 73.7386 64.2633 72.3982 65.0881 71.5733C65.913 70.7484 67.2535 70.7484 68.0784 71.5733L75.0615 78.5564L91.5593 62.0586C92.3842 61.2337 93.7246 61.2337 94.5495 62.0586C95.3744 62.8835 95.3744 64.2239 94.5495 65.0488L76.5584 83.0399C76.1602 83.4382 75.6233 83.6622 75.0615 83.6622Z"
                    fill="#E6E9ED"
                  />
                </svg>
              </div>
              <div className="login">
                <h3 className="loginheader " style={{ marginTop: "150px" }}>
                  Password reset successfully !
                </h3>
                <p className="mb-4 loginDescription">
                  Your password have been reset successfully. We're redirecting
                  you to the Sign in page!
                </p>
              </div>
              <div>
                {" "}
                <p className="mb-4 loginDescription">
                  In case you are not redirected automatically{" "}
                  <a onClick={accountinfopage} className="joingeo">
                    click here
                  </a>
                </p>
              </div>
            </div>
          ) : (
            <form className="login-form ">
              <div className=" login">
                <h3 className="loginheader textleft">Change Password</h3>
                <p className="mb-4 loginDescription textleft">
                  Make sure password is minimum 8 characters including a number
                  and a uppercase letter.
                </p>
              </div>
              <div className="Password mb-3 textleft">
                <style>
                  {`
                 input::-ms-reveal {
                 display: none;
                }
                `}
                </style>
                <label htmlFor="password" className="userName loginlabel">
                  Password
                </label>
                <span className="mandatorymark loginlabel">*</span>
                <div
                  className="password-container"
                  style={{ position: "relative" }}
                >
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className={`form-control ${
                      formData.Password ? "input-active" : ""
                    }`}
                    id="password"
                    placeholder="Enter Password"
                    name="Password"
                    value={formData.Password}
                    onChange={handleChange}
                  />
                  <IconButton
                    data-testid="toggle-password-visibility"
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
                {errors.Password && (
                  <div className="error-message mandatorymark">
                    {errors.Password}
                  </div>
                )}
              </div>
              <ul style={{ listStyleType: "none", paddingLeft: 0, paddingTop: 10 }}>
                  {" "}
                  {/* Remove padding to align to the extreme left */}
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.minLength ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.minLength ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    At least 8 characters
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.uppercase ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.uppercase ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one uppercase letter (A-Z)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.lowercase ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.lowercase ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one lowercase letter (a-z)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.digit ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.digit ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one digit (0-9)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.specialChar ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.specialChar ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one special character (!, @, #, $, %, ^,
                    &, *)
                  </li>
                </ul>
              <div className="Password mb-3 textleft">
                <style>
                  {`
                    input::-ms-reveal {
                    display: none;
                    }
                `}
                </style>
                <label
                  htmlFor="confirmPassword"
                  className="userName loginlabel"
                >
                  Confirm Password
                </label>
                <span className="mandatorymark loginlabel">*</span>
                <div
                  className="password-container"
                  style={{ position: "relative" }}
                >
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    className={`form-control ${
                      formData.ConfirmPassword ? "input-active" : ""
                    }`}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    name="ConfirmPassword"
                    value={formData.ConfirmPassword}
                    onChange={handleChange}
                  />
                  <IconButton
                    data-testid="toggle-confirm-password-visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {confirmPasswordVisible ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </div>
                {errors.ConfirmPassword && (
                  <div className="error-message mandatorymark">
                    {errors.ConfirmPassword}
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between mb-3">
                <button
                  type="submit"
                  className="forgotpasswordbutton"
                  onClick={ChangePasswordButtonClick}
                >
                  Change passwords
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
