// locationCoordinates.js

const locationCoordinates = {
  "usa": { center: [-98.5795, 39.8283], zoom: 4 }, // USA

  "state": {
    "alabama": { center: [-86.9023, 32.8067], zoom: 6 },
    "alaska": { center: [-149.4937, 64.2008], zoom: 6 },
    "arizona": { center: [-111.0937, 34.0489], zoom: 6 },
    "arkansas": { center: [-92.3731, 34.7465], zoom: 6 },
    "california": { center: [-119.4179, 36.7783], zoom: 6 },
    "colorado": { center: [-105.3581, 39.5501], zoom: 6 },
    "connecticut": { center: [-72.7554, 41.6032], zoom: 6 },
    "delaware": { center: [-75.5277, 38.9108], zoom: 6 },
    "florida": { center: [-81.5158, 27.6648], zoom: 6 },
    "georgia": { center: [-82.9001, 32.1656], zoom: 6 },
    "hawaii": { center: [-155.5828, 19.8968], zoom: 6 },
    "idaho": { center: [-114.7420, 44.0682], zoom: 6 },
    "illinois": { center: [-89.3985, 40.6331], zoom: 6 },
    "indiana": { center: [-86.1349, 40.2672], zoom: 6 },
    "iowa": { center: [-93.0977, 42.0115], zoom: 6 },
    "kansas": { center: [-98.4842, 38.5000], zoom: 6 },
    "kentucky": { center: [-84.2700, 37.8393], zoom: 6 },
    "louisiana": { center: [-91.9623, 30.9843], zoom: 6 },
    "maine": { center: [-69.4455, 45.2538], zoom: 6 },
    "maryland": { center: [-76.6413, 39.0458], zoom: 6 },
    "massachusetts": { center: [-71.3824, 42.4072], zoom: 6 },
    "michigan": { center: [-84.5361, 44.3148], zoom: 6 },
    "minnesota": { center: [-94.6859, 46.7296], zoom: 6 },
    "mississippi": { center: [-89.3985, 32.3547], zoom: 6 },
    "missouri": { center: [-91.8318, 37.9643], zoom: 6 },
    "montana": { center: [-110.3626, 46.8797], zoom: 6 },
    "nebraska": { center: [-99.9018, 41.4925], zoom: 6 },
    "nevada": { center: [-116.4194, 38.8026], zoom: 6 },
    "new hampshire": { center: [-71.5724, 43.1939], zoom: 6 },
    "new jersey": { center: [-74.4057, 40.0583], zoom: 6 },
    "new mexico": { center: [-105.8701, 34.5199], zoom: 6 },
    "new york": { center: [-74.0060, 43.2994], zoom: 6 },
    "north carolina": { center: [-79.0193, 35.7596], zoom: 6 },
    "north dakota": { center: [-101.0020, 47.5515], zoom: 6 },
    "ohio": { center: [-82.9071, 40.4173], zoom: 6 },
    "oklahoma": { center: [-97.0929, 35.0078], zoom: 6 },
    "oregon": { center: [-120.5542, 43.8041], zoom: 6 },
    "pennsylvania": { center: [-77.1945, 41.2033], zoom: 6 },
    "rhode island": { center: [-71.4774, 41.5801], zoom: 6 },
    "south carolina": { center: [-81.1637, 33.8361], zoom: 6 },
    "south dakota": { center: [-100.4388, 43.9695], zoom: 6 },
    "tennessee": { center: [-86.5804, 35.5175], zoom: 6 },
    "texas": { center: [-99.9018, 31.9686], zoom: 6 },
    "utah": { center: [-111.0937, 39.3209], zoom: 6 },
    "vermont": { center: [-72.5778, 44.5588], zoom: 6 },
    "virginia": { center: [-78.6569, 37.4316], zoom: 6 },
    "washington": { center: [-120.7401, 47.7511], zoom: 6 },
    "west virginia": { center: [-80.4549, 38.5976], zoom: 6 },
    "wisconsin": { center: [-89.6165, 44.5000], zoom: 6 },
    "wyoming": { center: [-107.2903, 43.0759], zoom: 6 },
  },

  "city": {
    "san francisco": { center: [-122.4194, 37.7749], zoom: 12 },
    "los angeles": { center: [-118.2437, 34.0522], zoom: 12 },
    "houston": { center: [-95.3698, 29.7604], zoom: 12 },
    "miami": { center: [-80.1918, 25.7617], zoom: 12 },
    "chicago": { center: [-87.6298, 41.8781], zoom: 12 },
    "new york city": { center: [-74.006, 40.7128], zoom: 12 },
    "dallas": { center: [-96.7970, 32.7767], zoom: 12 },
    "atlanta": { center: [-84.3880, 33.7490], zoom: 12 },
    "boston": { center: [-71.0589, 42.3601], zoom: 12 },
    "phoenix": { center: [-112.0740, 33.4484], zoom: 12 },
    "denver": { center: [-104.9903, 39.7392], zoom: 12 },
    "seattle": { center: [-122.3321, 47.6062], zoom: 12 },
    "las vegas": { center: [-115.1398, 36.1699], zoom: 12 },
    "austin": { center: [-97.7431, 30.2672], zoom: 12 },
    "detroit": { center: [-83.0458, 42.3314], zoom: 12 },
    "san diego": { center: [-117.1611, 32.7157], zoom: 12 },
  },
};

export default locationCoordinates;