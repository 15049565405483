const ValidationMessages = {
    firstNameAlphaNumeric : "First Name can only contain alphanumeric characters!",
    firstNameRequired  : "First Name is required!",
    lastNameAlphaNumeric : "Last Name can only contain alphanumeric characters!",
    lastNameRequired : "Last Name is required!",
    emailFormatRequired : "Email must be in the format: name@geoparking.com!",
    emailRequired: "Email is required!",
    phoneRequired : "Mobile number is required!",
    inValidPhone : "Mobile number is invalid!",
    departmentRequired: "Department is required!",
    termsAndConditons: "You must agree to the terms and conditions!",
    zipCodeDigits:"ZIP Code must contain only digits!",
    zipCode5: "ZIP Code must be 5 digits long!",
    zipCodeAddreess:"Zipcode does not match the address provided!",
    firstNameAphabets: "First Name must contain only alphabets!",
    lastNameAlphabets: "Last Name must contain only alphabets!",
    titleAlphabets: "Title must contain only alphabets!",
    newPasswordRequired: 'NewPassword is required!',
    confirmPasswordRequired: 'ConfirmPassword is required!',
    newandconfirmPasswordShouldMatch: 'New Password and Confirm Password should Match!',
    passwordRequired: "Password is required!",
    OTPRequired: "Please enter a valid OTP.",
    UserNameRequired : 'UserName is required',
    PasswordRequired : 'Password is required',
    InValidCredential :"Please Verify Entered Credentials!",
    emailAlredyExist: "Email already Exist!",
    requirements:"Password does not meet all requirements",

  };
  
  export default ValidationMessages;