import React, { useState } from 'react';
import RobotaxiLogin from '../../Images/LoginImage.png';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import GeoParkingInternalEmployeeVerification from './geoParkingInternalEmployeeEmailVerification';
import API_URLS from '../apiCalls/apiUrls';
import FadeLoader from "react-spinners/FadeLoader";
import { checkEmailExistence } from '../../Services/employeeService'
import { checkTemppassword, checkTempcreds } from '../../Services/userService'
import OwnerLogin from '../Owner/OwnerLogin';
import { Password } from '@mui/icons-material';
const Login = () => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [failedAttempts, setFailedAttempts] = useState(0);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [LogedInUser, setLogedInUser] = useState([]);
    const navigate = useNavigate();
    const [isInternalEmployeeEmailVerification, setInternalEmployeeEmailVerification] = useState(false);
    const [loginUserData, setLoginUserData] = useState({
        EmailId: '',
        Password: '',
        Rememberme: false,
    });
    const [owner, setLoginownerData] = useState({
        EmailId: '',
        tempPassword: '',
    });
    const [errors, setErrors] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isPasswordDisabled, setIsPasswordDisabled] = useState(false);
    const [loader, setLoader] = useState(false);
    const [ownerlogin, setIsownerlogin] = useState();
    const envname = process.env.REACT_APP_ENV_TXT || null;
    const handleCheckboxChange = () => {
        setLoginUserData({
            ...loginUserData,
            Rememberme: !loginUserData.Rememberme,
        });
    };

    const validateForm = () => {
        const errors = {};
        if (!loginUserData.EmailId) {
            errors.EmailId = 'Email is required';
        }
        if (!isPasswordDisabled && !loginUserData.Password) {
            errors.Password = 'Password is required';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const sendOTPByEmail = async (emailOTP) => {
        const emailID = {
            EmailId: emailOTP ? emailOTP.toLowerCase() : loginUserData.EmailId.toLowerCase(),
        };
        setLoader(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'x-functions-key': hostHeader,
                },
                body: JSON.stringify(emailID),
            };
            const response = await axios.post(
                `${hostPath}${API_URLS.BASE_URL_SEND_OTP_TO_EMAIL}`,
                requestOptions.body,
                { headers: requestOptions.headers }
            );
            if (response?.data) {
                setLoader(false);
                setInternalEmployeeEmailVerification(true);
            }
        } catch (error) {
            setLoader(false);
        }
    };

    const login = async (e) => {
        e.preventDefault();

        // Lowercase email for consistent checks
        const emailId = loginUserData.EmailId.toLowerCase();

        if (isPasswordDisabled) {
            const emailExists = await checkEmailExistence(emailId);
            if (!emailExists) {
                setErrors({ EmailId: 'Please enter a valid email' });
            } else {
                sendOTPByEmail(emailId);
            }
        } else if (validateForm()) {
            try {
                // Check if the password is temporary             
                const isTempPassword = await checkTemppassword(emailId);
                if (isTempPassword) {
                    const emailExists = await checkEmailExistence(emailId);
                    if (emailExists) {
                        setErrors({ EmailId: 'Please enter a valid email' });
                    } else {
                        const isValidtempcreds = await checkTempcreds(loginUserData.EmailId.toLowerCase(), loginUserData.Password);
                        if (isValidtempcreds) {
                            //alert("Entered credentials are temporary. Please reset your credentials.");
                            setLoginownerData({ EmailId: emailId.toLowerCase(), tempPassword: loginUserData.Password });
                            setIsownerlogin(true);
                            return;
                        }
                        else {
                            setErrors({ Password: "Entered temporary credentials are invalid" });
                            return;
                        }
                    }
                }
                // Prepare login request
                const modifiedLoginUserData = {
                    ...loginUserData,
                    EmailId: emailId
                };

                const response = await axios.post(
                    `${hostPath}/api/LoginUser`,
                    modifiedLoginUserData,
                    { headers: { 'x-functions-key': `${hostHeader}` } }
                );

                if (response.data[0]) {
                    const loggedInUser = response.data;
                    localStorage.clear();
                    localStorage.setItem('LogedInUser', JSON.stringify(loggedInUser[0]?.entityRegistrationID));
                    // Store initials
                    const firstInitial = loggedInUser[0]?.firstName?.charAt(0) || '';
                    const lastInitial = loggedInUser[0]?.lastName?.charAt(0) || '';
                    const initials = (firstInitial + lastInitial).toUpperCase();
                    localStorage.setItem('initials', JSON.stringify(initials));

                    navigate('/dashboard');
                } else {
                    handleFailedLoginAttempt();
                }
            } catch (error) {
                console.error('Error during login:', error);
            }
        }
    };
    const handleFailedLoginAttempt = () => {
        setFailedAttempts(failedAttempts + 1);

        if (failedAttempts >= 5) {
            setErrors({
                Password: 'Too many incorrect login attempts. Please try again in 3 minutes',
            });
            setIsFormDisabled(true);

            setTimeout(() => {
                setIsFormDisabled(false);
                setErrors({ Password: '' });
                setFailedAttempts(0);
            }, 180000); // 3-minute cooldown
        } else {
            setErrors({ Password: 'Please enter a valid email/password' });
        }
    };


    const forgotPassword = (e) => {
        e.preventDefault();
        navigate('/ResetPassword');
    };

    const handleChange = (e) => {
        const { value } = e.target;
        const trimValue = value.trim();
        setLoginUserData({ ...loginUserData, EmailId: value });
        if (trimValue.toLowerCase().endsWith('@geoparking.com')) {
            setErrors({});
            setIsPasswordDisabled(true);
        } else {
            setIsPasswordDisabled(false);
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
            {isInternalEmployeeEmailVerification ? (
                <GeoParkingInternalEmployeeVerification
                    EmailId={loginUserData.EmailId}
                    sendOTPByEmail={sendOTPByEmail}
                />
            ) : ownerlogin ? (
                <OwnerLogin ownerlogin={ownerlogin} owner={owner} />
            ) : (
                <div className="container-fluid p-0">
                    <div className="row m-0">
                        <div className="col-md-7 p-0">
                            <img src={RobotaxiLogin} alt="Login" className="GeoparkingImage" />
                        </div>
                        <div className="col-md-5">
                            <form className="login-form loginfirst">
                                <div className="login">
                                    {envname && <span className="restricted-badge">{envname}</span>}
                                    <h3 className="loginheader textleft">Login</h3>
                                    <p className="mb-4 loginDescription textleft">
                                        Log in to your GeoParking Technologies Dashboard
                                    </p>

                                </div>
                                <div className="Username mb-4 textleft formbox">
                                    <label htmlFor="username" className="userName loginlabel">
                                        Email
                                    </label>
                                    <span className="mandatorymark loginlabel">*</span>
                                    <input
                                        type="text"
                                        className={`form-control ${loginUserData.EmailId ? 'input-active' : ''}`}
                                        id="username"
                                        placeholder="Enter Email"
                                        value={loginUserData.EmailId}
                                        onChange={handleChange}
                                        disabled={isFormDisabled}
                                    />
                                    {errors.EmailId && (<div className="error-message mandatorymark">{errors.EmailId}</div>)}
                                </div>

                                {!isPasswordDisabled && (
                                    <>
                                        <div className="Password mb-3 textleft">
                                            <label htmlFor="password" className="userName loginlabel"> Password</label>
                                            <span className="mandatorymark loginlabel">*</span>
                                            <div className="password-container" style={{ position: 'relative' }}>
                                                <input
                                                    type={passwordVisible ? 'text' : 'password'}
                                                    className={`form-control ${loginUserData.Password ? 'input-active' : ''}`}
                                                    id="password"
                                                    placeholder="Enter Password"
                                                    value={loginUserData.Password}
                                                    onChange={(e) => setLoginUserData({ ...loginUserData, Password: e.target.value })}
                                                    disabled={isPasswordDisabled || isFormDisabled}
                                                />
                                                <IconButton
                                                    data-testid="toggle-password-visibility"
                                                    onClick={togglePasswordVisibility}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </div>

                                            {errors.Password && (
                                                <div className="error-message mandatorymark">
                                                    {errors.Password}
                                                </div>
                                            )}
                                        </div>

                                        <div className="d-flex justify-content-between mb-5">
                                            <div className="remember">
                                                <label className="control control--checkbox mb-0">
                                                    <input
                                                        type="checkbox"
                                                        checked={loginUserData.Rememberme}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <span className="caption">Remember me</span>
                                                    <div className="control__indicator"></div>
                                                </label>
                                            </div>
                                            <div className="forgot-pass">
                                                <a href="#" onClick={forgotPassword}>Forgot Password</a>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <button type="submit" className="btnn-primary mb-3 login-button" onClick={login} disabled={isFormDisabled} > {isPasswordDisabled ? 'Send OTP' : 'Login'} </button>
                                <div className="joinus remember"> New to GeoParking? <Link to="/signup" className="joingeo"> Join us now </Link> and be part of the future!</div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );

};

export default Login;
