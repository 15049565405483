import React, { useEffect, useRef, useState } from "react";
import * as atlas from "azure-maps-control";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


import axios from "axios";
import LockIcon from '@mui/icons-material/Lock';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GarageIcon from '@mui/icons-material/Garage';
import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation as useRouterLocation } from "react-router-dom";
import locationCoordinates from './locationCoordinates.js';


import SearchBox from "./SearchBox.jsx";
import "./AzureMapComponent.css";
import Navbar from "../NavBar/Navbar.jsx";

const mapSubscriptionKey =
  "EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG";

const AzureMapComponent = () => {
  const mapRef = useRef(null);
  const containerRef = useRef(null);
  const dataSourceRef = useRef(null);
  const searchedDataSourceRef = useRef(null);
  // New State Variables for Filters
  const [initializedRef, setInitializedRef] = useState(false);

  const [pins, setPins] = useState([]);
  const [selectedPin, setSelectedPin] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const routerLocation = useRouterLocation();
  const [mapInitialized, setMapInitialized] = useState(false);

  const [selectedSalesperson, setSelectedSalesperson] = useState('');
  const [salesList, setSalesList] = useState([]);


  const [statusFilters, setStatusFilters] = useState({
    Green: false,
    Yellow: false,
    Red: false,
    GatedFenced: false,
    Garage: false,
    Fee: false,
    NotRegistered: false,
    REIT: false
  });
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;

  const socketRef = useRef(null);

  const getCRMLink = (id) => {
    const baseUrl = process.env.REACT_APP_CRM_BASE_URL; // Dynamically fetched base URL
    const appId = process.env.REACT_APP_CRM_APP_ID; // Dynamically fetched app ID
  
    return `${baseUrl}?etn=cr0f9_property&id=${id}&pagetype=entityrecord&forceUCI=1&appid=${appId}`;
  };
  
  const handleStatusFilterChangeButton = (filterName) => {
    setStatusFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  const parseFiltersFromURL = () => {
    const queryParams = new URLSearchParams(routerLocation.search);

    // Parse Status Filters
    const green = queryParams.get("green") === "true";
    const yellow = queryParams.get("yellow") === "true";
    const red = queryParams.get("red") === "true";
    const reit = queryParams.get("reit") === "true";
    const gatedFenced = queryParams.get("gatedFenced") === "true";
    const garage = queryParams.get("garage") === "true";
    const fee = queryParams.get("fee") === "true";
    const notRegistered = queryParams.get("notRegistered") === "true";

    // Parse Salesperson
    const salesperson = queryParams.get("salesperson") || '';

    // Update the state
    setStatusFilters({
      Green: green,
      Yellow: yellow,
      Red: red,
      GatedFenced: gatedFenced,
      Garage: garage,
      Fee: fee,
      NotRegistered: notRegistered,
      REIT: reit
    });

    setSelectedSalesperson(salesperson);
  };

  useEffect(() => {
    parseFiltersFromURL();
  }, [routerLocation.search]);

  useEffect(() => {

    const queryParams = new URLSearchParams(routerLocation.search);
    const locationQuery = queryParams.get("location");


    console.log("Raw locationQuery from URL:", queryParams.get("location")); // Should log "san francisco"
    console.log("Decoded locationQuery:", locationQuery); // Should log "san francisco"

    if (mapInitialized && locationQuery) {
      const queryLower = locationQuery.toLowerCase();
      console.log("Lowercased locationQuery:", queryLower);
      // Search in country
      if (locationCoordinates.country && queryLower === "usa") {
        const { center, zoom } = locationCoordinates.country.usa;
        console.log("Centering on USA:", center, "Zoom:", zoom);
        mapRef.current.setCamera({
          center,
          zoom,
        });
        return;
      }

      // Search in state
      const stateKeys = Object.keys(locationCoordinates.state).map((state) =>
        state.toLowerCase()
      );
      if (stateKeys.includes(queryLower)) {
        const stateKey = Object.keys(locationCoordinates.state).find(
          (state) => state.toLowerCase() === queryLower
        );
        const { center, zoom } = locationCoordinates.state[stateKey];
        console.log(`Centering on State: ${stateKey}`, center, "Zoom:", zoom);
        mapRef.current.setCamera({
          center,
          zoom,
        });
        return;
      }

      // Search in city
      const cityKeys = Object.keys(locationCoordinates.city).map((city) =>
        city.toLowerCase()
      );
      if (cityKeys.includes(queryLower)) {
        const cityKey = Object.keys(locationCoordinates.city).find(
          (city) => city.toLowerCase() === queryLower
        );
        const { center, zoom } = locationCoordinates.city[cityKey];
        console.log(`Centering on City: ${cityKey}`, center, "Zoom:", zoom);
        mapRef.current.setCamera({
          center,
          zoom,
        });
        return;
      }

      // If not found in any category
      console.warn(
        `Location "${locationQuery}" not found in country, state, or city coordinates.`
      );
    } else {
      if (!mapInitialized) {
        console.log("Map is not initialized yet.");
      }
      if (!locationQuery) {
        console.log("No locationQuery found in URL.");
      }
    }
  }, [routerLocation.search, mapInitialized]);


  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedPin(null);
  };

  const handleLocationSelect = (position, propertyData = null) => {
    console.log("handleLocationSelect invoked");
    console.log("Received position:", position);
    console.log("Received propertyData:", propertyData);

    if (mapRef.current) {
      // Determine zoom level based on search result specificity
      let zoomLevel = 10; // Default zoom for city
      if (propertyData && propertyData.type === "street") {
        zoomLevel = 16; // Closer zoom for street addresses
        console.log("Setting zoom level to 16 for street address.");
      } else if (propertyData && propertyData.type === "city") {
        zoomLevel = 10; // Zoom level for city
        console.log("Setting zoom level to 10 for city.");
      } else {
        console.log("Property type not specified or unrecognized. Using default zoom level:", zoomLevel);
      }

      // Set camera to the searched property's location with dynamic zoom
      mapRef.current.setCamera({
        center: [position.lon, position.lat],
        zoom: zoomLevel,
      });
      console.log(`Camera set to center: [${position.lon}, ${position.lat}] with zoom: ${zoomLevel}`);

      if (searchedDataSourceRef.current) {
        // Clear any existing searched pin
        searchedDataSourceRef.current.clear();
        console.log("Cleared existing searched pins.");

        if (propertyData) {
          // Add the searched property as a new feature
          const searchedFeature = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [position.lon, position.lat],
            },
            properties: {
              ...propertyData,
              isSearched: true, // Flag to use the blue pin
            },
          };
          searchedDataSourceRef.current.add(searchedFeature);
          console.log("Added searched feature:", searchedFeature);
        } else {
          // If no property data is provided, optionally add a generic searched pin
          const genericSearchedFeature = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [position.lon, position.lat],
            },
            properties: {
              isSearched: true,
            },
          };
          searchedDataSourceRef.current.add(genericSearchedFeature);
          console.log("Added generic searched feature:", genericSearchedFeature);
        }
      }
    } else {
      console.warn("Map reference is not initialized.");
    }
  };

  // Throttle function to prevent rapid resizing
  const throttle = (func, delay) => {
    let lastCall = 0;
    return (...args) => {
      const now = new Date().getTime();
      if (now - lastCall < delay) return;
      lastCall = now;
      return func(...args);
    };
  };

  // Throttled resize function
  const throttledResize = throttle(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, 100); // Adjust delay as needed






  const fetchData = async () => {
    try {
      const response = await fetch(`${hostPath}/api/GetAllSales`, {
        method: 'GET',
        headers: {
          "x-functions-key": `${hostHeader}`,
          "Content-Type": "application/json"
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data);
      setPins(data);

      const salesPersons = new Set();
      for (let i = 0; i < data.length; i++) {
        salesPersons.add(data[i].Salesperson);
      }

      const salesList = Array.from(salesPersons);
      setSalesList(salesList);
      console.log("this is the sales list: ", salesList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRefreshClick = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData(); // Initial fetch on component mount
    const intervalId = setInterval(fetchData, 21600000); // Poll every 6 hours

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  const isTrue = (value) => {
    if (typeof value !== "string") return false; // Return false if value is not a string
    return value.trim().toLowerCase() === "true";
  };




  useEffect(() => {
    if (!initializedRef.current) {
      const userCoordinates = [-97.5, 39.8];;
      const azureMap = new atlas.Map("azureMapContainer", {
        center: userCoordinates,
        zoom: 3.5,
        view: "Auto",
        style: 'satellite',
        authOptions: {
          authType: "subscriptionKey",
          subscriptionKey: mapSubscriptionKey,
        },
        showLogo: false,
        showFeedbackLink: false,
        showCopyright: false,
        language: "en-US",
      });

      azureMap.events.add("ready", () => {
        console.log("Azure Map is initialized.");
        const dataSource = new atlas.source.DataSource(null, {
          cluster: true,
          clusterRadius: 25,
        });

        azureMap.sources.add(dataSource);
        dataSourceRef.current = dataSource;

        // Initialize searchedDataSourceRef
        const searchedDataSource = new atlas.source.DataSource();
        azureMap.sources.add(searchedDataSource);
        searchedDataSourceRef.current = searchedDataSource;

        // Function to load images to the map
        const loadImageToMap = (map, id, url) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = () => {
              map.imageSprite.add(id, img).then(resolve).catch(reject);
            };
            img.onerror = (err) => {
              console.error(`Failed to load image ${id} from ${url}`, err);
              reject(err);
            };
            img.src = url;
          });
        };


        const prefixes = ["green-pin", "yellow-pin", "red-pin"];
        const suffixes = ["garage", "fence", "fee", "REIT"]; // Exclude 'inactive' from suffixes

        const generateUniqueImageIds = () => {
          const uniqueIds = new Set();

          // Generate all suffix combinations
          const totalCombinations = Math.pow(2, suffixes.length); // 2^4 = 16
          for (let i = 0; i < totalCombinations; i++) {
            const suffixCombo = [];
            for (let j = 0; j < suffixes.length; j++) {
              if (i & (1 << j)) {
                suffixCombo.push(suffixes[j]);
              }
            }

            // Add combinations for each prefix
            prefixes.forEach((prefix) => {
              // Add inactive combinations explicitly for green-pin
              if (prefix === "green-pin") {
                uniqueIds.add(
                  `${prefix}-inactive${suffixCombo.length > 0 ? "-" + suffixCombo.join("-") : ""}`
                );
              }


              // Add the standard combinations for all prefixes
              uniqueIds.add(
                `${prefix}${suffixCombo.length > 0 ? "-" + suffixCombo.join("-") : ""}`
              );
            });
          }

          // Add combinations explicitly for "green-pin-inactive" without any suffix
          uniqueIds.add("green-pin-inactive");

          return Array.from(uniqueIds); // Convert Set to Array
        };

        const uniqueImageIds = generateUniqueImageIds();
        console.log("Generated Unique Image IDs:", uniqueImageIds);

        Promise.all(
          uniqueImageIds.map((imageId) =>
            loadImageToMap(azureMap, imageId, `/icons/${imageId}.png`)
          )
        )
          .then(() => {
            console.log("All images loaded successfully.");
            // Add other map layers or features as needed
          })

          .then(() => {
            // Add clustering layers
            azureMap.layers.add(
              new atlas.layer.BubbleLayer(dataSource, null, {
                filter: ["has", "point_count"],
                radius: ["step", ["get", "point_count"], 10, 100, 15, 750, 20],
                color: [
                  "step",
                  ["get", "point_count"],
                  "lightblue",
                  100,
                  "green",
                  750,
                  "red",
                ],
                strokeWidth: 2,
                strokeColor: "white",
              })
            );

            // Add labels for clusters
            azureMap.layers.add(
              new atlas.layer.SymbolLayer(dataSource, null, {
                filter: ["has", "point_count"],
                iconOptions: { image: "none" },
                textOptions: {
                  textField: "{point_count_abbreviated}",
                  offset: [0, 0.4],
                  color: "black",
                  font: ["StandardFont-Bold"],
                  size: 12,
                },
              })
            );

            // Add symbols for individual pins
            const symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
              filter: ["!", ["has", "point_count"]],
              iconOptions: {
                image: ["get", "image"],
                allowOverlap: true,
                size: 0.7,
              },
            });

            azureMap.layers.add(symbolLayer);

            // Add SymbolLayer for the searched pin
            const searchedSymbolLayer = new atlas.layer.SymbolLayer(searchedDataSource, null, {
              filter: ["has", "isSearched"],
              iconOptions: {
                image: "blue-pin", // Use the blue pin image
                allowOverlap: true,
                size: 1.0, // Slightly larger size for emphasis
              },
            });
            azureMap.layers.add(searchedSymbolLayer);

            // Add click event for property pins
            azureMap.events.add("click", symbolLayer, (e) => {
              if (e.shapes && e.shapes.length > 0) {
                const shape = e.shapes[0];
                let properties;

                if (typeof shape.getProperties === "function") {
                  properties = shape.getProperties();
                } else {
                  properties = shape.properties;
                }

                // Log the entire pin object to the console
                console.log("Pin Clicked:", properties);

                setSelectedPin(properties);
                setDialogOpen(true);
              }
            });

            // Add click event for the searched pin (optional)
            azureMap.events.add("click", searchedSymbolLayer, (e) => {
              if (e.shapes && e.shapes.length > 0) {
                const shape = e.shapes[0];
                let properties = shape.getProperties();

                // Log the searched pin object to the console
                console.log("Searched Pin Clicked:", properties);

                setSelectedPin(properties);
                setDialogOpen(true);
              }
            });

            // Add map controls
            azureMap.controls.add(
              [
                new atlas.control.ZoomControl(),
                new atlas.control.CompassControl(),
                new atlas.control.StyleControl({
                  mapStyles: [
                    'road',
                    'grayscale_dark',
                    'grayscale_light',
                    'night',
                    'satellite',
                    'satellite_road',
                    'road_shaded_relief',
                  ],
                }),
              ],
              {
                position: 'bottom-right', // Position of the controls
              }
            );

            setInitializedRef(true);
            mapRef.current = azureMap;
            setMapInitialized(true);
          })
          .catch((err) => {
            console.error("Error loading images:", err);
          });
      });
    }
  }, []);

  useEffect(() => {
    if (dataSourceRef.current) {
      if (pins.length > 0) {
        populatePins(pins);
      } else {
        dataSourceRef.current.clear();
      }
    }
  }, [pins, statusFilters, selectedSalesperson]);

  const generateImageId = (pin) => {
    // Determine the prefix based on StatusReason and Status
    let prefix;
    if (pin.StatusReason === "1" && pin.Status === "0") {
      prefix = "yellow-pin";
    } else if (
      (pin.StatusReason === "100000000" || pin.StatusReason === "5") &&
      pin.Status === "0"
    ) {
      prefix = "green-pin";
    } else if (pin.StatusReason === "2" && pin.Status === "1") {
      prefix = "red-pin";
    } else {
      prefix = "unknown-pin"; // Fallback for unexpected cases
    }

    // Determine suffixes based on attributes
    const suffixes = [];
    if (pin.StatusReason === "100000000") { // Direct check for inactive
      suffixes.push("inactive");
    }
    if (isTrue(pin.garage)) suffixes.push("garage");
    if (isTrue(pin.GatedOrFenced)) suffixes.push("fence");
    if (isTrue(pin.Fee)) suffixes.push("fee");
    if (isTrue(pin.REIT)) suffixes.push("REIT");

    // Generate the final image ID
    const imageId = `${prefix}${suffixes.length > 0 ? "-" + suffixes.join("-") : ""}`;

    console.log("Generated Image ID:", imageId);

    return imageId;
  };

  const populatePins = (pinsData) => {
    const features = pinsData
      .filter((pin) => {
        // Filtering logic based on active filters
        const anyStatusSelected = Object.keys(statusFilters)
          .filter((key) => key !== "GatedFenced" && key !== "Fee" && key !== "Garage" && key !== "NotRegistered" && key !== "REIT")
          .some((key) => statusFilters[key]);

        const isGatedFencedFilterActive = statusFilters.GatedFenced;
        const isFeeFilterActive = statusFilters.Fee;
        const isGarageFilterActive = statusFilters.Garage;
        const isNotRegisteredFilterActive = statusFilters.NotRegistered;
        const isReitFilterActive = statusFilters.REIT;

        const statusReasonValue = pin.StatusReason;
        const statusValue = pin.Status;
        const gatedFenced = isTrue(pin.GatedOrFenced);
        const feeValue = isTrue(pin.Fee);
        const garageValue = isTrue(pin.garage);
        const reitValue = isTrue(pin.REIT);

        let statusMatch = true;
        if (anyStatusSelected) {
          statusMatch = false;
          if (statusFilters.Green && statusValue === "0" && (statusReasonValue === "100000000" || statusReasonValue === "5")) {
            statusMatch = true;
          }
          if (statusFilters.Yellow && statusReasonValue === "1" && statusValue === "0") {
            statusMatch = true;
          }
          if (statusFilters.Red && statusReasonValue === "2" && statusValue === "1") {
            statusMatch = true;
          }
        }

        let gatedFencedMatch = true;
        if (isGatedFencedFilterActive) {
          gatedFencedMatch = gatedFenced;
        }

        let feeMatch = true;
        if (isFeeFilterActive) {
          feeMatch = feeValue;
        }

        let garageMatch = true;
        if (isGarageFilterActive) {
          garageMatch = garageValue;
        }

        let notRegisteredMatch = true;
        if (isNotRegisteredFilterActive) {
          notRegisteredMatch = statusValue === "0" && statusReasonValue === "100000000";
        }

        let reitMatch = true;
        if (isReitFilterActive) {
          reitMatch = reitValue;
        }

        let salespersonMatch = true;
        if (selectedSalesperson) {
          salespersonMatch = pin.Salesperson === selectedSalesperson;
        }

        return (
          statusMatch &&
          gatedFencedMatch &&
          feeMatch &&
          garageMatch &&
          notRegisteredMatch &&
          salespersonMatch &&
          reitMatch
        );
      })
      .map((pin) => {
        const latValue = pin.Latitude;
        const lonValue = pin.Longitude;

        if (latValue == null || lonValue == null) {
          console.warn(
            `Skipping pin with missing coordinates: ${pin.PropertyAddress || "Unknown"}`
          );
          return null;
        }

        const lat = parseFloat(latValue.toString().trim());
        const lon = parseFloat(lonValue.toString().trim());

        if (isNaN(lat) || isNaN(lon)) {
          console.error(`Invalid coordinates for pin: ${latValue}, ${lonValue}`);
          return null;
        }

        // Dynamically generate the image ID
        const imageId = generateImageId(pin);

        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [lon, lat],
          },
          properties: {
            ...pin,
            image: imageId, // Assign the generated image ID
          },
        };
      })
      .filter(Boolean); // Remove any null values from the map step

    console.log("Features being added:", features);

    dataSourceRef.current.clear();
    dataSourceRef.current.add(features);
  };

  // Correctly map StatusReason values to the appropriate icon colors
  // const getImageFromStatus = (pin) => {
  //   for (let mapping of pinImageMappings) {
  //     if (mapping.match(pin)) {
  //       return mapping.imageId;
  //     }
  //   }
  //   // Default image if no match is found
  //   return "yellow-pin";
  // };

  // Helper functions to determine pin status
  const isWon = (pin) => (pin.StatusReason === "100000000" || pin.StatusReason === "5") && pin.Status === "0";
  const isInProgress = (pin) => pin.StatusReason === "1";
  const isLost = (pin) => pin.StatusReason === "2";

  return (
    <>
      <Navbar />
      <div className="map-fullscreen-container" ref={containerRef}>
        {/* Filters on top left */}
        <div className="filters-overlay">
          <div className="filters-row">
            <div className="filters">
              <Button
                className={`filter-button green-filter ${statusFilters.Green ? 'active' : ''}`}
                onClick={() => handleStatusFilterChangeButton("Green")}
                startIcon={<img src="/icons/won-icon.png" alt="Won" className="icon" />}
              >
                Won
              </Button>
              <Button
                className={`filter-button yellow-filter ${statusFilters.Yellow ? 'active' : ''}`}
                onClick={() => handleStatusFilterChangeButton("Yellow")}
                startIcon={<img src="/icons/in-progress-icon.png" alt="In Progress" className="icon" />}
              >
                In Progress
              </Button>
              <Button
                className={`filter-button red-filter ${statusFilters.Red ? 'active' : ''}`}
                onClick={() => handleStatusFilterChangeButton("Red")}
                startIcon={<img src="/icons/lost-icon.png" alt="Lost" className="icon" />}
              >
                Lost
              </Button>
              <Button
                className={`filter-button reit-filter ${statusFilters.REIT ? 'active' : ''}`}
                onClick={() => handleStatusFilterChangeButton("REIT")}
                startIcon={<img src="/icons/reit-icon.png" alt="REIT" className="icon" style={{ width: '16px', height: '16px' }} />}
              >
                REIT
              </Button>
            </div>
            <div className="filters">
              <Button
                className={`filter-button garage-filter ${statusFilters.Garage ? 'active' : ''}`}
                onClick={() => handleStatusFilterChangeButton("Garage")}
                startIcon={<img src="/icons/garage-icon.png" alt="Lost" style={{ width: '18px', height: '18px' }} />}
              >
                Garage
              </Button>
              <Button
                className={`filter-button gated-filter ${statusFilters.GatedFenced ? 'active' : ''}`}
                onClick={() => handleStatusFilterChangeButton("GatedFenced")}
                startIcon={<img src="/icons/fence-icon.png" alt="Lost" style={{ width: '16px', height: '14px' }} />}
              >
                Gated/Fenced
              </Button>
              <Button
                className={`filter-button fee-filter ${statusFilters.Fee ? 'active' : ''}`}
                onClick={() => handleStatusFilterChangeButton("Fee")}
                startIcon={<AttachMoneyIcon className="icon" />}
              >
                Fee
              </Button>

              {statusFilters.Green && (
                <Button
                  className={`filter-button inactive-filter ${statusFilters.NotRegistered ? 'active' : ''}`}
                  onClick={() => handleStatusFilterChangeButton("NotRegistered")}
                  startIcon={<BlockIcon className="icon" />}
                >
                  Not Registered
                </Button>
              )}
            </div>
          </div>

        </div>
        {/* Search box on top right */}
        <div className="search-overlay" >
          <SearchBox onLocationSelect={handleLocationSelect} />
        </div>
        {/* Map container */}
        <div id="azureMapContainer" className="map-container" ></div>
        {/* Refresh button at bottom center */}
        <div className="refresh-button-overlay">
          <Button variant="contained" color="primary" onClick={handleRefreshClick}>
            Refresh Data
          </Button>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 16,
            overflow: 'hidden',
            position: 'fixed',
            bottom: '16px',
            left: '50%',
            transform: 'translateX(-50%)',
            margin: 0,
            padding: '8px 16px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          },
        }}
        BackdropProps={{
          style: { backgroundColor: 'transparent' },
        }}
      >
        <DialogContent style={{ display: 'flex', padding: 0 }}>
          {selectedPin && (
            <>
              {/* Left side: Larger Image container */}
              <div style={{ width: '40%', position: 'relative', height: '80%' }}>
                <img
                  src="/icons/robo-icon.png"
                  alt="Robotaxi"
                  style={{ width: '100%', height: '100%', objectFit: 'center' }}
                />
              </div>

              {/* Right side: Property details with increased font sizes and spacing */}
              <div style={{ width: '60%', padding: '16px', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                {/* Close button at the top right */}
                <IconButton
                  onClick={() => setDialogOpen(false)}
                  style={{ position: 'absolute', top: -5, right: -5 }}
                >
                  <CloseIcon />
                </IconButton>

                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', fontSize: '1.2rem', lineHeight: 1.6 }}
                >
                  {`${selectedPin.PropertyAddress}, ${selectedPin.State} ${selectedPin.Zip}`}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: '1rem', color: '#555', lineHeight: 1.6 }}
                  gutterBottom
                >
                  {`Number of Parking Spaces: ${selectedPin.NumberOfParkingSpaces || 'N/A'}`}
                </Typography>

                {/* Property Type */}
                <Typography
                  variant="body2"
                  style={{ fontSize: '1rem', color: '#555', lineHeight: 1.6 }}
                  gutterBottom
                >
                  {`Property Type: ${selectedPin.PropertyType || 'N/A'}`}
                </Typography>

                {/* Icons row with increased spacing and centered vertically */}
                <div style={{ display: 'flex', gap: '12px', marginTop: '12px', alignItems: 'center' }}>
                  {isWon(selectedPin) && (
                    <img src="/icons/won-icon.png" alt="Won" style={{ width: '24px', height: '24px' }} />
                  )}
                  {isInProgress(selectedPin) && (
                    <img src="/icons/in-progress-icon.png" alt="In Progress" style={{ width: '24px', height: '24px' }} />
                  )}
                  {isLost(selectedPin) && (
                    <img src="/icons/lost-icon.png" alt="Lost" style={{ width: '24px', height: '24px' }} />
                  )}
                  {isTrue(selectedPin.garage) && (
                    <img src="/icons/garage-icon.png" alt="Lost" style={{ width: '20px', height: '20px' }} />
                  )}
                  {isTrue(selectedPin.GatedOrFenced) && (
                    <img src="/icons/fence-icon.png" alt="Lost" style={{ width: '22px', height: '20px' }} />
                  )}
                  {isTrue(selectedPin.Fee) && (
                    <AttachMoneyIcon style={{ fontSize: 24 }} />
                  )}
                  {isTrue(selectedPin.REIT) && (
                    <img src="/icons/reit-icon.png" alt="REIT" style={{ width: '16px', height: '18px' }} />
                  )}
                  {selectedPin.StatusReason === "100000000" && (
                    <img src="/icons/unregistered-icon.png" alt="Unregistered" style={{ width: '22px', height: '22px' }} />
                  )}

                </div>
                {selectedPin.Id && (
                  <Typography
                    variant="body2"
                    style={{ marginTop: '16px', color: '#0078D4', cursor: 'pointer', fontSize: '1rem' }}
                  >
                    <a
                      href={getCRMLink(selectedPin.Id)}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: '#0078D4', fontWeight: 'bolder', fontSize: '15px' }}
                    >
                      Click here to launch the CRM opportunity: <u>Link</u>
                    </a>
                  </Typography>
                )}
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>




    </>
  );
};

export default AzureMapComponent;
