import { useCallback, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import PopUPModal from '../utils/Modal'

const Frame = (props) => {
    const { className = '', overrides, isFrame1Open, onClose, handleContinue, handleSearchclick, alertpopup, handleAddressreset,searchError, areasMarked, addressvalue, defaultaddress, navigateToDashboard, currentProperty,closeaddressReset, ...rest } = props;
    const [address, setAddress] = useState();
    useEffect(() => {
        addressvalue ? setAddress(currentProperty) : setAddress(defaultaddress);
    }, [addressvalue, currentProperty, defaultaddress]);

    if (!isFrame1Open) {
        return null;
    }
    const formattedAddress = addressvalue
        ? address?.freeformAddress?.replace(/"/g, '')
        : `${address?.enityMailingAdderess}, ${address?.cityId}, ${address?.zipcode}`;
    if ((! props.isDashboard || props.isAddressSelected) &&
            !alertpopup &&
            areasMarked.length === 0 &&
            searchError
        ) {
            return null; 
        }
    

    return (
        <div className={`confirm-property-location-parent ${className}`}>
            {
                props?.isDashboard && !props?.isAddressSelected ?
                    <div>
                        <b className="confirm-property-location1">{PopUPModal.Goto_Dashboard} </b>
                        <div className="yes-continue-wrapper" onClick={navigateToDashboard}>
                            <b className="yes-continue">Yes, Go Back</b>
                        </div>
                        <div className="no-go-back-wrapper" onClick={onClose}>
                            <b className="yes-continue">No, Stay Here</b>
                        </div>
                    </div> :
                    props.isAddressSelected && !alertpopup && areasMarked.length === 0 && !searchError? (
                        <div>
                            <b className="confirm-property-location">{PopUPModal?.Confirm_Property_Location}</b>
                            <div className='address'>{PopUPModal.Current_property_address}: {formattedAddress}<br /></div>
                            <div className="are-you-sure">{PopUPModal.Goto_NextStep}</div>
                            <div className="yes-continue-wrapper" onClick={handleContinue}>
                                <b className="yes-continue">Yes, Continue</b>
                            </div>
                            <div className="no-go-back-wrapper" onClick={onClose}>
                                <b className="yes-continue">No, Go Back</b>
                            </div>
                        </div>
                    ) : (
                        alertpopup ? (
                            <div>
                                <b className="confirm-property-location1">{PopUPModal?.Change_Address} </b>
                                <div className="yes-continue-wrapper" onClick={handleAddressreset}>
                                    <b className="yes-continue">Yes, Go Back</b>
                                </div>
                                <div className="no-go-back-wrapper" onClick={closeaddressReset}>
                                    <b className="yes-continue">No, Stay Here</b>
                                </div>
                            </div>
                        ) : ( !searchError &&
                            <div>
                                <b className="marker_confirmation">{PopUPModal?.ProceedToNext}</b>
                                <div className="before-proceeding-please">{PopUPModal?.ProceedToNextDescription}</div>
                                <div className="yes-continue-wrapper" onClick={handleContinue}>
                                    <b className="yes-continue">Yes, Continue</b>
                                </div>
                                <div className="no-go-back-wrapper" onClick={onClose}>
                                    <b className="yes-continue">No, Go Back</b>
                                </div>
                            </div>
                        )
                    )}
        </div>
    );
};

Frame.propTypes = {
    className: PropTypes.string,
};

export default Frame;
