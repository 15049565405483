import React, { useState } from 'react';
import RobotaxiLogin from '../../Images/LoginImage.png';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ValidationMessages from '../utils/validationMessages';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DemoUserLogin = () => {
    const demoUsername = process.env.REACT_APP_DEMO_USERNAME;
    const demoPassword = process.env.REACT_APP_DEMO_PASSWORD;
    const BaseURL = `${window.location.origin}/Home`; // Dynamically build the login URL based on the environment
    const envname =  process.env.REACT_APP_ENV_TXT || null;
    const [loginUserData, setLoginUserData] = useState({
        UserName: '',
        Password: '',
    });
    const [errors, setErrors] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);


    const validateForm = () => {
        const errors = {};
        if (!loginUserData.UserName) {
            errors.UserName = ValidationMessages.UserNameRequired;
        }
        if (!loginUserData.Password) {
            errors.Password = ValidationMessages.PasswordRequired;
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const login = (e) => {
        e.preventDefault();
        if (validateForm()) {
            if (loginUserData.UserName === demoUsername && loginUserData.Password === demoPassword) {
                localStorage.setItem('isauth', 'true');
                window.location.href = BaseURL
            } 
            else{
                 localStorage.setItem('isauth', 'false');
                 toast.error(ValidationMessages.InValidCredential);
            }
        }
    };
    const handleChange = (e) => {
        const { value } = e.target;
        setLoginUserData({ ...loginUserData, UserName: value });
    };
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-md-7 p-0">
                    <img src={RobotaxiLogin} alt="Login" className="GeoparkingImage" />
                </div>
                <div className="col-md-5">
                    <form className="login-form loginfirst">
                        <div className="login">
                            <h3 className="loginheader textleft">Restricted Access</h3>
                            {envname && <span className="restricted-badge">{envname}</span>}
                            <p className="mb-4 loginDescription textleft">
                              Enter your credentials to access the environment
                            </p>
                        </div>
                        <div className="Username mb-4 textleft formbox">
                            <label htmlFor="username" className="userName loginlabel"> UserName </label>
                            <span className="mandatorymark loginlabel">*</span>
                            <input type="text" className={`form-control ${loginUserData.UserName ? 'input-active' : ''}`} id="username" placeholder="Enter UserName" value={loginUserData.UserName} onChange={handleChange} />
                            {errors.UserName && (<div className="error-message mandatorymark">{errors.UserName}</div>)}
                        </div>

                        <div className="Password mb-3 textleft">
                            <label htmlFor="password" className="userName loginlabel"> Password</label>
                            <span className="mandatorymark loginlabel">*</span>
                            <div className="password-container" style={{ position: 'relative' }}>
                                <input type={passwordVisible ? 'text' : 'password'} className={`form-control ${loginUserData.Password ? 'input-active' : ''}`} id="password" placeholder="Enter Password" value={loginUserData.Password} onChange={(e) => setLoginUserData({ ...loginUserData, Password: e.target.value })} />
                                <IconButton
                                    data-testid="toggle-password-visibility"
                                    onClick={togglePasswordVisibility}
                                    className="toggle-password-visibility"
                                >
                                    {passwordVisible ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </div>
                            {errors.Password && (<div className="error-message mandatorymark">{errors.Password}</div>)}
                        </div>
                        <button type="submit" className="btnn-primary mb-3 login-button" onClick={login} > Submit </button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default DemoUserLogin;
