import React from 'react';
import PropTypes from 'prop-types';
import PopUPModal from '../utils/Modal'

const Frame1 = (props) => {
    const { className = '', overrides, isFrameOpen, onClose, defaultaddress, userData, currentPropertyaddress, ...rest } = props;
    if (!isFrameOpen) {
        return null;
    }
    return (
        <div className={props.isAddressSelected ? "ParkingSpots" : `frame-parent ${className}`} {...rest}>
            <div className="group-container">
                <img className="group-icon" alt="" src="Group91.png" />
                <br/>
                <b className="locate-property">
                    {props.isAddressSelected ? PopUPModal?.Parking_Spots_And_Entrances : PopUPModal?.Locate_Property_Heading}
                </b>
            </div>
            <div className={props.isAddressSelected ? "Markspots-Content" : "to-mark-pick-up"}>
                {props.isAddressSelected ? PopUPModal?.Mark_Parking_Spots :
                    <> <div className='Address_field'><span >{PopUPModal.Current_property_address}:</span> {props.currentPropertyaddress}</div>
                        {PopUPModal?.Locate_Property_Description}
                    </>
                }
            </div>
            <div className={props.isAddressSelected ? "close-Markspots" : "close-wrapper"} onClick={onClose} style={{ cursor: 'pointer' }}>
                <b className="close">Close</b>
            </div>
        </div>
    );
};

Frame1.propTypes = {
    className: PropTypes.string,
    overrides: PropTypes.object,
    isFrameOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Frame1;
