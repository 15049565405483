const PopUPModal = {
    Locate_Property_Heading : "LOCATE PROPERTY",
    Parking_Spots_And_Entrances: "DESIGNATE PARKING SPACES* AND RELATED ENTRANCES",
    Current_property_address: "Current property address",
    Locate_Property_Description: "If the above street address is not the street address of the parking lot you are on boarding, then you need to use the search box in the top right to select the street address of your parking lot you are on boarding, once you have entered and see the proper property address in the Satellite image, then click save.",
    Confirm_Property_Location: "Confirm Property Location",
    Mark_Parking_Spots: "Select Points from the dropdown in the upper right to then mark/designate (i.e. pin drop) on the map. Mark/designate as many points as you need on the map. Pick parking spots/spaces that are the most likely (they are NOT required to be open/available) to be vacant/open. In a later step, you'll use the unavailability calendar to block off times when you are not allowing these parking spaces to be used by Robotaxis. And, if a Robotaxi arrives when a parking space(s) you designated on the satellite map is occupied, this is not a problem as the Robotaxi will automatically just leave your parking lot and move on to another GeoParking parking lot down the street (you never need to put a physical sign in your parking lot/parking space saying “no parking/Robotaxi parking only” as you are never guaranteeing parking is available).",
    ProceedToNext: "Are you sure you want to proceed ?",
    ProceedToNextDescription: "Don't worry! You can change the parking lot markers at any time from your dashboard.",
    Change_Address: "Are you sure you want change the address?",
    Goto_NextStep: "Are you sure you want to go to the next step?",
    Goto_Dashboard: "Are you sure want to go back to Dashboard?",
    Dasboard_Description: "We're thrilled to have you on board. With GeoParking, managing your parking lots has never been easier. Let's maximize your potential together!"

  };
  
  export default PopUPModal;
