import React, { useState } from 'react';
import '../Owner/OwnerRegistration.css';
import './BankDetails.css';
import PropertImage from '../../Images/Property.png';
import { RxDotsVertical } from 'react-icons/rx'; // Import the dots icon
import { FaEdit, FaTrash } from "react-icons/fa";

const BankAccountList = ({ nickName, details, property, accountNumber, routingNumber, accountHolderName, BankID, handleSelectedowner, bankName, SelectedCard, handleDelete, handleEdit, handleRegsiterOwner, entityId, SelectedBank, onEdit,
  onDelete, }) => {
  const [selectedCardone, setSelectedCard] = useState(null);


  return (
    <div
      className={`property-card ${(BankID === details?.bankDetailID) ? "selected-card" : ""
        }`}
      onClick={(e) => setSelectedCard(details.id)}
      style={{ position: "relative" }}
    >
      <div className="property-content">
        <div className="owner-details">
          <img src={PropertImage} alt="Owner" className="owner-icon" />
        </div>
        <div className="property-info">
          <div className="info-block">
            <p className="info-header">Name</p>
            <p className="info-data">{accountHolderName}</p>
          </div>
          <div className="info-block">
            <p className="info-header">NickName</p>
            <p className="info-data">{nickName}</p>
          </div>
          <div className="info-block">
            <p className="info-header">AccountNumber</p>
            <p className="info-data">{accountNumber}</p>
          </div>
          <div className="info-block">
            <p className="info-header">RoutingNumber</p>
            <p className="info-data">{routingNumber}</p>
          </div>

          <div className="info-block">
            <p className="info-header">bankName</p>
            <p className="info-data">{bankName}</p>
          </div>
          <div className="actions d-flex align-items-center">
            <FaEdit
              className="icon edit-icon me-4"
              onClick={onEdit}
              title="Edit Account"
            />
            <FaTrash
              className="icon delete-icon"
              onClick={onDelete}
              title="Delete Account"
            />
          </div>

        </div>
      </div>
    </div>
  );
};



export default BankAccountList;
